import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMenuProvider } from '../../../../../contexts/MenuProvider'

import Image from '../../../../Utils/Image/Image'

import '../Navigation/MyAccount.scss'
import { t } from 'i18next'
import { useUserLang } from '../../../../../contexts/LanguageSwitcherProvider'

const Sidebar = ({ setUserOpened=null }) => {

    const { lang } = useUserLang()
    const navigate = useNavigate()
    const sidebarItems = useMenuProvider()
    const activeCat = window
        .location
        .href
        .split('/')
    let activeCatOriginal = ''
    activeCatOriginal = lang==='en' ? activeCat[4] : activeCat[5]

    return (
        <div className="sidebar">
            <ul>
                {sidebarItems && sidebarItems.map((item, index) => {
                    let itemSplit = item.to.split('/')
					return (
						<li key={index} className={itemSplit[2] === activeCatOriginal ? 'active' : ''}>
							<span onClick={() => { 
                                if(setUserOpened){
                                    setUserOpened(false); 
                                }
                                navigate((lang==='en' ? '' : '/' + lang) + item.to) 
                            }}>
								<Image src={item.image}/> { t(item.name) }
							</span>
							{ itemSplit[2] === activeCatOriginal && <div className="active-line" /> }
						</li>
					)
                })}
            </ul>
        </div>
    )
}

export default Sidebar