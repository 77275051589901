import React from 'react'
import './Blog.scss'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { useUserLang } from '../../contexts/LanguageSwitcherProvider'

const RelatedBlogBox = ({slug, title, content, image, date, key, prefix='blog'}) => {

    const { lang } = useUserLang()

    return (
        <div className="col-lg-4 col-xl-3 col-sm-6" key={key}>
            <div className="post-box">
                <div className={"post-body" + (!image ? ' no-image' : '' )}>
                    { image && <Link to={(lang !== 'en' ? '/' + lang : '') + '/blog/'+slug} className="link">
                        <div className="post-image-box">
                            <div className="post-image"
                                style={{ backgroundImage: 'url("'+ image +'")' }}
                            ></div>
                        </div>
                    </Link> }
                    <div className="blog-intro">
                        <div className="blog-date">
                            {date && date.split('T')[0]}
                        </div>
                            <div className='blog-content-container'>
                            <Link to={(lang !== 'en' ? '/' + lang : '') + '/blog/'+slug} className="title-link">
                                <h2>{title}</h2>
                            </Link>
                            <div className="post-content">
                                <p dangerouslySetInnerHTML={{ __html: content.split(" ").splice(0,40).join(" ") }} />
                            </div>
                        </div>
                        <Link to={(lang !== 'en' ? '/' + lang : '') + '/'+ prefix +'/'+slug} className="link btn">{ t("READ MORE")}</Link>
                    </div>
                </div>                 
            </div>              
        </div>
    )
}

export default RelatedBlogBox