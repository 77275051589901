import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useUserLogged } from '../../../contexts/UserLoggedProvider'


export default function SplashScreen(){

    const [ hideSplash, setHideSplash ] = useState(false)
    const { userLogged } = useUserLogged()

    useEffect(() => {
        if(userLogged!==null){
            setTimeout(() => {
                setHideSplash(true)
            }, 3000)
        }
    }, [userLogged])

    if(hideSplash) return <></>

    return (
        <SplashSection style={{ backgroundImage: "url(/images/mobile-assets/splash.gif)" }}></SplashSection>
    )
}

const SplashSection = styled.section`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    z-index: 1000002!important;
    @media (min-width: 1024px){
        max-width: var(--max-app-width);
        margin: auto;
    }
`