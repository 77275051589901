import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import TableSelection from '../TableSelection/TableSelection'

import { buttonElementCss } from './ButtonElementCss'

const Button = ({
	onClickEvent=null, 
	colorTheme="default", 
	secondary=false, 
	children, 
	theme, 
	className, 
	to='',
	href='',
	stakes=false,
	light=false,
	disabled=false,
	lobbyRef=null
}) => {

	const navigate = useNavigate()
	const [ stakesOpen, setStakesOpen ] = useState(false)


	const isExternalLink = (url) => {
		const tmp = document.createElement('a');
		tmp.href = url;
		return tmp.host !== window.location.host;
	}


	const handleClick = (e) => {

        if(lobbyRef){
            window.scrollTo(0, lobbyRef.offsetTop)
            return
        }

		if(stakes){ setStakesOpen(!stakesOpen) }

		if(!onClickEvent && !to) return

		e.preventDefault()

		if(isExternalLink(to) && window){
			window.open( to, '_blank' )
			return
		}
		
		if(onClickEvent){
			onClickEvent()
		}
		
		if(to){ 
			navigate(to)
			return
		}

	}

	if(stakes){
		return ( 
			<ButtonStakeWrap>
				<ButtonElement 
					disabled={disabled}
					onClick={handleClick} 
					secondary={secondary.toString()} 
					colorTheme={colorTheme==='home' ? 'default' : colorTheme} 
					className={'btn' + (theme ? ' ' + theme : '')  + (className ? ' ' + className : '') + (secondary ? ' btn-secondary' : '')}>
						{ children }
				</ButtonElement>
				<TableSelection open={stakes && stakesOpen} />
			</ButtonStakeWrap>
		)
	}else{
		return (
			<ButtonElement 
				disabled={disabled}
				onClick={handleClick} 
				secondary={secondary.toString()} 
				colorTheme={colorTheme==='home' ? 'default' : colorTheme} 
				light={light.toString()}
				className={'btn' + (theme ? ' ' + theme : '')  + (className ? ' ' + className : '') + (secondary ? ' btn-secondary' : '')}>
					{ children }
			</ButtonElement>
		)
	}
}

export default Button

const ButtonStakeWrap = styled.div`
    position: relative;
    display: inline-block;
`

const ButtonElement = styled.button`
	${buttonElementCss}
`