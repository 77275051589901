import React from 'react'

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    TumblrShareButton,
    TumblrIcon,
    ViberShareButton,
    ViberIcon,
    EmailShareButton,
    EmailIcon
} from 'react-share'

const ShareButtons = ({title, url, twitterHandle, tags, media}) => {

    return(
        <div>
            <FacebookShareButton url={url} >
                <FacebookIcon  size={40} round={true}/>
            </FacebookShareButton>

            <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
                <TwitterIcon  size={40} round={true} />
            </TwitterShareButton>

            <TelegramShareButton url={url} >
                <TelegramIcon  size={40} round={true}/>
            </TelegramShareButton>

            <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon  size={40} round={true}/>
            </WhatsappShareButton>

            <LinkedinShareButton url={url} >
                <LinkedinIcon  size={40} round={true}/>
            </LinkedinShareButton>

            <PinterestShareButton url={url} media={media}>
                <PinterestIcon  size={40} round={true}/>
            </PinterestShareButton>

            <RedditShareButton url={url} title={title} >
                <RedditIcon  size={40} round={true} />
            </RedditShareButton>

            <TumblrShareButton url={url} >
                <TumblrIcon  size={40} round={true}/>
            </TumblrShareButton>

            <ViberShareButton url={url} >
                <ViberIcon  size={40} round={true}/>
            </ViberShareButton>

            <EmailShareButton url={url} >
                <EmailIcon  size={40} round={true}/>
            </EmailShareButton>
        </div>
    )

}

export default ShareButtons