import React, { useEffect, useState, useMemo, useRef } from "react"
import axios from "axios"
import { locales } from '../../../data/locales'
import { useNavigate } from 'react-router-dom'

import TextInput from "../../Utils/TextInput/TextInput";
import SelectInput from '../..//Utils/SelectInput/SelectInput'
import CheckboxButton from "../../Utils/CheckboxButton/CheckboxButton"

import SubmitButton from "../../Utils/SubmitButton/SubmitButton";
import ErrorOutput from "../../Output/ErrorOutput/ErrorOutput";
import Passwords from "../../Utils/Passwords/Passwords";

import './QuickRegister.scss'
import LoadingOutput from "../../Output/LoadingOutput/LoadingOutput";
import { useAppOptions } from "../../../contexts/AppOptionsProvider";

import SMSActivationModal from "../SMSActivationModal/SMSActivationModal";
import PhoneNumberInput from "../../Utils/PhoneNumberInput/PhoneNumberInput";

import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { t } from "i18next";

export default function QuickRegister(){
    
    const [ countries, setCountries ] = useState([]);
    const countriesObject = useMemo(() => [], []);
    const phonePrefixObject = useMemo(() => [], []);
	const navigate = useNavigate()

    const [ accountSmsActivated, setAccountSmsActivated ] = useState(false)
    
    useEffect(() => {
        locales.map((item) => {            
            countriesObject.push({
                value: item.code,
                label: item.name
            });

            return null
        });

        setCountries(countriesObject);
        
    },[countriesObject,phonePrefixObject])


    const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // change to your site key
    const [ recaptchaResponse, setRecaptchaResponse ] = useState();
    const v2Callback = (token, refreshToken) => {
        setRecaptchaResponse(token)
    }
    
    const appOptions = useAppOptions()
    

    const recaptchaRef = useRef(null);

    const [ successfullResponse, setSuccessfullResponse ] = useState(false);
    const [ errorResponse, setErrorResponse ] = useState(false);
    const [ loading, setLoading ] = useState(false)

    const [ smsCode, setSmsCode ] = useState(null);
    const [ username, setUsername ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ mobile, setMobile] = useState('');
    const [ country, setCountry ] = useState(null);
    const [ validatePasswords, setValidatePasswords ] = useState(false);

    const [ termsConditions, setTermsConditions ] = useState(null);
    const [ yearCheck, setYearCheck] = useState(null);
    const [ subscribeCheck, setSubscribeCheck ] = useState(false);

    const requiredFields = [
        { value: email , setValue: setEmail , val:0},
        { value: username , setValue: setUsername, val:1 },
        { value: password , setValue: setPassword , val:2},
        { value: confirmPassword , setValue: setConfirmPassword , val:5},
        { value: country , setValue: setCountry, val:4},
        { value: mobile , setValue: setMobile, val:10},
        { value: termsConditions, setValue: setTermsConditions, val:6, type: 'checkbox' },
        { value: yearCheck, setValue: setYearCheck, val:7, type: 'checkbox' },
    ]

    function validatePassword(pass){
        let testStr = "^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$"

        if(!pass) return false

        if(pass.match(testStr)){
            return true
        }
        return false;
    }

    const validateEmail = (email) => {
        return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    const handleQuickRegisterSubmit = async e => {
        e.preventDefault();

        setSuccessfullResponse(false)
        setErrorResponse('')

        let requiredFieldsMissing = false;

        setValidatePasswords(true);

        if(username.indexOf(' ') >= 0){
            setErrorResponse(t("Username can't contain empty space"))
            requiredFieldsMissing = true
        }

        if(!validateEmail(email)){
            setErrorResponse(t("You have entered an invalid email address!"))
            requiredFieldsMissing = true
        }
        
        if(password !== confirmPassword){
            setErrorResponse(t("Passwords does not match!"));
            requiredFieldsMissing = true
        }

        if(validatePassword(password) === false){
            setErrorResponse(t("Password must be at least 8 characters long contain a number and an uppercase letter"));
            requiredFieldsMissing = true
        }
        
        
        requiredFields.map((item) => {
            if(item.value === '' || item.value === null || item.value === false){
                setErrorResponse(t("Please fill in all the required fields."));
                requiredFieldsMissing = true
                if(item.type==='checkbox'){
                    item.setValue(false)
                }else{
                    item.setValue(null)
                }
            }
            return null;
        })


        if(!recaptchaResponse && requiredFieldsMissing){
            setErrorResponse(t("Please fill in all the required fields and complete the reCaptcha process."))
        }

        if(!recaptchaResponse && !requiredFieldsMissing){
            setErrorResponse(t("Please complete the reCaptcha process."))
        }
        
        if(requiredFieldsMissing === false && recaptchaResponse ){
            
            setLoading(true)
            let parameters = {
                "username" : username,
                "email":email,
                "password":password,
                "password2":confirmPassword,
                "mobile_phone_number":mobile,
                "country":country,
                "terms_v1_opt_in": true,
                "terms_v2_opt_in": true,
                "terms_v3_opt_in": true,
                "recaptcha": recaptchaResponse
            }

            axios.post(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/auth/v2/quick-sign-up/',parameters)
            .then((response) => {
                setLoading(false)
                setSuccessfullResponse(true)
                setLoading(false)
                axios.post(process.env.REACT_APP_AFF_REGISTER, {
                    affcode: 'MIMAGAMES'
                }).then((response) => console.log(response))
            })
            .catch(function (error) {
                recaptchaRef.current?.reset();
                setErrorResponse(error.response.data.details);
                setLoading(false);
            });
        }
        return;
    }

    return(
        <>
            <div className="login-container form-section">
                <div className='quick-register-popup' id='login-popup'>
                    <div className="quick-register-wrapper">
                        <form className="container" onSubmit={handleQuickRegisterSubmit} noValidate> 
                                <div className="row">
                                    <div className="col-12">
                                        <h3>Register and play Free</h3>
                                    </div>
                                    <div className="col-md-6">
                                        <TextInput 
                                            label={t("Username") + " *"}
                                            type="text"
                                            value={username}
                                            setValue={setUsername} 
                                            preventSpaces={true}
                                            required={true}
                                            autoFocus={true}
                                        />

                                        <TextInput 
                                            label={t("E-mail") + " *"}
                                            type="email" 
                                            inputmode="email"
                                            value={email}
                                            setValue={setEmail} 
                                            required={true}    
                                        />
                                        
                                        <Passwords password={{password,setPassword}} confirmPassword={{confirmPassword,setConfirmPassword}} validatePasswords={validatePasswords} setValidatePasswords={setValidatePasswords} />

                                        <div id="recaptcha-badge">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={sitekey}
                                                theme={'dark'}
                                                hl='en'
                                                onChange={v2Callback}
                                                tabindex={0}
                                            />
                                        </div>
                                    
                                    </div>
                                    <div className="col-md-6">

                                        <PhoneNumberInput 
                                            label={t("Mobile Phone Number") + " *"}
                                            note={t("Required for activating your account via SMS Code")} 
                                            required={true}
                                            value={mobile} 
                                            setValue={setMobile} />

                                        <MobileSpacer />

                                        <SelectInput placeholder={t("Select Country")} label={t("Country") + " *"} options={countries}  setValue={setCountry } required={true} value={country}  errorMessage={ t("Please select your Country") } />
                                        
                                        <fieldset className="register-checkboxes">

                                            <CheckboxButton value={termsConditions} className={termsConditions === false ? 'required-field' : ''} setValue={setTermsConditions}>
                                                { 
                                                    appOptions && appOptions.acf && appOptions.acf.terms_and_conditions_pdf && (
                                                        <>
                                                            { t("I confirm that I") } <a rel="noreferrer" target="_blank" className="terms" href="/security-privacy/terms-conditions-free/">{ t("read") }</a> { t("the Terms") } &amp; { t("Conditions") }. *
                                                        </>
                                                    )
                                                }
                                            </CheckboxButton>

                                            <CheckboxButton value={subscribeCheck} setValue={setSubscribeCheck}>
                                                { t("I agree to subscribe to our newsletter for latest promotions and information") }
                                            </CheckboxButton>

                                            <CheckboxButton value={yearCheck} className={yearCheck === false ? 'required-field' : ''} setValue={setYearCheck}>
                                                { t("I am over 18 years old.") } *
                                            </CheckboxButton>

                                        </fieldset>

                                        { !loading && (
                                            <div className="quick-button-row">
                                                <SubmitButton theme="light">{ t("Register") }</SubmitButton>
                                                <span className="orlogin" onClick={() => {
                                                    navigate('/login') }}>{ t("or Login here") }</span>
                                            </div>
                                        ) }
                                        { loading && <LoadingOutput>{ t("Registering") }...</LoadingOutput> }                                                
                                    </div>
                                </div>
                                { errorResponse && <ErrorOutput>{ errorResponse }</ErrorOutput> }
                                
                        </form>
                    </div>
                </div>
            </div>
            { !errorResponse && successfullResponse && (
                <SMSActivationModal 
                    setSmsCode={setSmsCode} 
                    smsCode={smsCode} 
                    mobilePhoneMumber={mobile} 
                    email={email} 
                    password={password} 
                    setPassword={setPassword} 
                    setAccountSmsActivated={setAccountSmsActivated} 
                    accountSmsActivated={accountSmsActivated} />
            )}
        </>
        )
}

const MobileSpacer = styled.div`
    @media (min-width: 0px) and (max-width: 767px){
        height: 2rem;
    }
`