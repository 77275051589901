import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import _uniqueId from 'lodash/uniqueId'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import './PhoneNumberInput.scss'
import { t } from 'i18next';

export default function PhoneNumberInput({
    className='',
    id=_uniqueId('phone-input-'),
    label='',
    value,
    required = false,
    setValue,
    children,
    note = '',
    errorMessage = false, 
}) {

    const phoneRef = useRef()
    const [ requredClass, setRequredClass ] = useState("");
    const [ requiredMessage, setRequiredMessage ] = useState(null)

    useEffect(() => {
        if(!phoneRef.current) return
        if(!value) return
        phoneRef.current.numberInputRef.focus()
    }, [value])

    useEffect(() => {
        if(value===null && required){
            setValue('');
            setRequredClass('required-input');
            if(errorMessage){
                setRequiredMessage(errorMessage);
            }else{
                setRequiredMessage( t("This field is required"));
            }
        }else if(value !== null && value !== '' && value !== undefined){
            setRequredClass('');
            setRequiredMessage(null);
        }
    
    }, [value, setValue, required, errorMessage])


    return (
        <div className={`form-row form-phone-row` + (className ? ' ' + className : '')}>
            { label && <label htmlFor={id}>{label}</label> }
            <div className="form-input-wrap">
                { children }
                <PhoneInput 
                    ref={phoneRef}
                    className={requredClass}
                    value={value}
                    onChange={(phone) => { 
                        setValue((phone ? '+' : '') + phone)
                    }} 
                    required={required}
                    enableSearch={true} 
                    alwaysDefaultMask={false} />
            </div>
            { note && <MessageNote>{ note }</MessageNote> }

            { requiredMessage && <div className="required-field-message">{ requiredMessage }</div> }
        
        </div>
        
    );
}

const MessageNote = styled.div`
    position: absolute;
    font-size: 0.75rem;
    padding: 0.4rem 0.5rem;
    background: #f1ffb0;
    color: black;
    border-radius: 3px;
    right: 0;
`