import React, { useState, useEffect } from 'react'
import _uniqueId from 'lodash/uniqueId'

import './TextInput.scss'
import { t } from 'i18next';

export default function TextInput({
    className='', 
    id=_uniqueId('text-input-'), 
    label='', 
    theme, 
    type, 
    name, 
    value, 
    placeholder,
    required,
    setValue, 
    children, 
    readonly= false, 
    requestFieldChange = false, 
    textType='text', 
    preventSpecialChars = false, 
    preventSpaces = false, 
    errorMessage = false, 
    minLength=1,
    maxlength=-1, 
    minAmount=null,
    maxAmount=null,
    autoFocus=false, 
    autoComplete="",
    inputmode="text",
    setRequiredCheck = false,
    onlyUpperCase = false
}) {
  
    const [ requredClass, setRequredClass ] = useState("");
    const [ requiredMessage, setRequiredMessage ] = useState(null);
    const [ emailValid, setEmailValid ] = useState(true);

    useEffect(() => {
        
        if((value === null  ) && required){
            setValue('');
            setRequredClass('required-input');
            if(errorMessage){
                setRequiredMessage(errorMessage);
            }else{
                setRequiredMessage( t("This field is required"));
            }
        }else if(value !== null && value !== '' && value !== undefined){
            if(textType === 'email'){
                
                if(!validateEmail(value)){
                    setEmailValid(false);
                    setRequiredMessage( t("Invalid email format"));
                    setRequredClass("required-input");
                }else{
                    setEmailValid(true);
                    setRequiredMessage("");
                    setRequredClass("");
                }
            }else{
                setRequredClass('');
                setRequiredMessage(null);
            }
        }
    
    }, [value, setValue, required, textType, errorMessage])


    useEffect(() => {
        if(!maxAmount) return
        if(value>maxAmount){
            setValue(maxAmount)
        }
    }, [value, maxAmount, setValue])
    
    useEffect(() => {

        if(minAmount===null) return

        if(value!==""){
            if(value<minAmount){
                setRequiredMessage( t("Minimum amount is ") + minAmount)
                setRequredClass("required-input")
            }
        }

        
    }, [value, minAmount, setValue])


    function requiredCheck(e){
        if(preventSpecialChars === true){
            if(specialChars(e.target.value)){
                return
            }
        }

        if(preventSpaces === true){
            if(preventSpace(e.target.value)){
                return
            }
        }

        if(textType === 'number'){
            if(isNaN(e.target.value)){
                return
            }
        }

       
        
        if(e.target.value === '' && required ){
            setRequredClass("required-input");
        }

        setValue(e.target.value)
        
        if(setRequiredCheck !== false){
            
            setRequiredCheck(true) 
                
        }
            
        
    }

    
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    function preventSpace(string){
        let format = /[!@#$%^&*()+={};':"\\|,.<>/?\s]+/;
        
        if(format.test(string)){
        return true;
        } else {
        return false;
        }
    }

    function specialChars(string){
        let format = /[!@#$%^&*()_+\-={};':"\\|,.<>/?]+/;
        
        if(format.test(string)){
        return true;
        } else {
        return false;
        }
    }
    
    return (
        <>
            <div className={`form-row` + (className ? ' ' + className : '')}>
                { label && <label htmlFor={id}>{label}</label> }
                <div className="form-input-wrap">
                    { children }
                    <input 
                        className={'input-text' + (theme ? ' ' + theme : '') +' '+ requredClass}
                        id={id}
                        type={type}
                        name={name}
                        inputMode={inputmode}
                        placeholder={placeholder}
                        value={value !== null ? (value && (onlyUpperCase ? value.toUpperCase() : value)) : ''}
                        onChange={ requiredCheck }
                        readOnly={readonly}
                        maxLength={maxlength}
                        minLength={minLength}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete}
                    />
                </div>

                { requiredMessage && emailValid && <div className="required-field-message">{ requiredMessage }</div> }
                { !emailValid && <div className="required-field-message">{ t("Invalid email format")}</div> }
            
            </div>
        </>
        
    );
}