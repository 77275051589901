import React, { useEffect, useState } from "react"
import { useUserLogged } from "./UserLoggedProvider"
import { useAppLoaded } from "./AppLoadedProvider"

import { useQuickRegisterPopup } from "./LoginPopupProvider"
import axios from "axios"

import { useUserWalletProvider } from "./UserWalletProvider"

export const UserSessionContext = React.createContext({
	userSession: null,
	setUserSession: () => {},
	customLoginOpened: false,
	setCustomLoginOpened: () => {},
})


export const UserSessionProvider = ({ children }) => {
	const { setUserWallet } = useUserWalletProvider()
	const { userLogged, setUserLogged } = useUserLogged()
	const { setAppLoaded } = useAppLoaded()
	const { setQuickRegisterPopup } = useQuickRegisterPopup()
	const [ userSession, setUserSession ] = useState(null)
	/* eslint-disable */
	const [ currency, setCurrency ] = useState('')

	const [ customLoginOpened, setCustomLoginOpened ] = useState(false);


	useEffect(() => {
			if(userSession) { 
				setUserLogged(true)
				return
			}
			if(localStorage.getItem('authJwt')){
				axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/accounts/profile/profile-data/',{
					headers:{
						"Authorization": "JWT " + localStorage.getItem('authJwt')
					}
				}).then((response) => {
					setUserSession(response.data)
					setUserLogged(true)
					setAppLoaded(true)
				}).catch((error) => {
					setUserLogged(false)
					localStorage.removeItem('authJwt')
					window.location.reload(false)
				})
			}else{
				setAppLoaded(true);
				setUserLogged(false)
			}
			

	}, [userSession, setUserSession, setUserLogged, userLogged])



	useEffect(() => {

		if(!userLogged) return
		if(!localStorage.getItem('authJwt')) return
		//start listening to the watchBalance event
		var currency_symbols = { 'USD': '$', 'EUR': '€', 'GBP': '£', 'INR': '₹', 'RUB': '₽' }

		axios.get(process.env.REACT_APP_API_URL_STAGING + "/api/v1/engine/payments/wallet/deposit-amounts/", {
			headers:{
				"Authorization": "JWT " + localStorage.getItem('authJwt')
			}
		}).then((response) =>{
			setUserWallet({
				amount: response.data.play_money,
				currency: currency_symbols['USD']
			});

			setCurrency(currency_symbols['USD']);
			
		})
		
	}, [userLogged, localStorage.getItem('authJwt')])
	
	useEffect(() => {
		const hashString = window.location.hash.substring(1);
		if (hashString !== "") {
			if(hashString==='quick-register'){
				setQuickRegisterPopup(true)
			}
		}
	}, [])

	return (
		<UserSessionContext.Provider value={{ userSession, setUserSession, customLoginOpened, setCustomLoginOpened }}>
			{ children }
		</UserSessionContext.Provider>
	)

}



export const useUserSession = () => {

	const { userSession, setUserSession, customLoginOpened, setCustomLoginOpened } = React.useContext(UserSessionContext)

	if(userSession === undefined && setUserSession === undefined ){
		throw new Error('useUserSession was used outside its Provider!')
	}

	return { userSession, setUserSession, customLoginOpened, setCustomLoginOpened }

}