import React, { useState } from "react"


export const UserLangContext = React.createContext({
	userLang: false,
	setUserLang: () => {}
})

export const LangSwitcherProvider = ({ children }) => {

    var langStorage = 'en';

	if(undefined !== window){
		let parts = window.location.pathname.split("/")
		let langStorage = parts[1]
		langStorage = langStorage.length > 2 ? "en" : parts[1]
		localStorage.setItem('lang', langStorage)
	}

    if (localStorage.getItem('lang')) {
        langStorage = localStorage.getItem('lang')
		langStorage = langStorage.length>2 ? 'en' : langStorage
		localStorage.setItem('lang', langStorage)
    }

	const [lang, setLang] = useState(langStorage);

	return (
		<UserLangContext.Provider value={{ lang, setLang }}>
			{ children }
		</UserLangContext.Provider>
	)

}

export const useUserLang = () => {

	const { lang, setLang } = React.useContext(UserLangContext)

	if(lang === undefined && setLang === undefined ){
		throw new Error('useUserLang was used outside its Provider!')
	}

	return { lang, setLang }

}