import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import _uniqueId from 'lodash/uniqueId'

import './SelectInput.scss'
import { t } from 'i18next';

export default function SelectInput({
    id=_uniqueId('select-input-'), 
    className, 
    label='', 
    theme, 
    options, 
    name, 
    value, 
    placeholder = t("Select..."), 
    required, 
    setValue, 
    errorMessage = false }) {
    
    
    const [ requredClass, setRequredClass ] = useState("");
    const [ requiredMessage, setRequiredMessage ] = useState(null);
    const [ initValue, setInitValue ] = useState(true);
    
    useEffect(() => {
        
        if(initValue && !value){
            setValue('')
            setInitValue(false)
            return;
        }
        
       if(value === null && !initValue){
            setValue('');
            setRequredClass('required-input');
            if(errorMessage){
                setRequiredMessage(errorMessage);
            }else{
                setRequiredMessage( t("This field is required"));
            }
        }else if(value !== null && value !== '' && value !== undefined){
            setRequredClass('');
            setRequiredMessage(null);
        }
        
    }, [value, setValue, initValue, errorMessage])
    
    function requiredCheck(e){
        if(e.value === '' && required ){
            setRequredClass("required-input");
        }
        setValue(e.value)
        
    }

    const customStyles = {
        ///.....
        menuPortal: provided => ({ 
            ...provided, 
            zIndex: 9999 
        }),
        menu: provided => ({ 
            ...provided, 
            zIndex: 9999 
        }),
        menuList: (base) => ({
          ...base,
          background: '#000',
          color: '#131313',
        })
        ///.....
    }
    
    
    return (
        <div className={"form-row " + (className ? className + ' ' : '') + requredClass}>
            { label && <label htmlFor={id}>
                {label}
            </label> }
            <Select
                //menuIsOpen={true} for testing only
                classNamePrefix="select-input" 
                menuPortalTarget={document.body} 
                styles={customStyles} 
                id={id} 
                className="select-indentifier" 
                options={options} 
                onChange={requiredCheck} 
                value={options && options.filter(pp => pp.value === value)} 
                placeholder={placeholder} />

            { requiredMessage && <div className="required-field-message"> { errorMessage ? errorMessage : 'This field is required' } </div> }
        </div>
        
    );
}