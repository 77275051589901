import React, { useEffect, useState, useRef, useCallback } from "react"
import {Link, useNavigate, useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import styled from "styled-components"
import axios from 'axios'

import Image from '../components/Utils/Image/Image'
import Container from "./../components/Layout/Container"

import { useUserSession } from "../contexts/UserSessionProvider"
import { useUserLogged } from '../contexts/UserLoggedProvider'
import { t } from "i18next"

import LoadingOutput from "../components/Output/LoadingOutput/LoadingOutput"
import LivePokerLobbyList from "../components/Sections/LivePokerLobby/LivePokerLobbyList"

import { liveTables, onlineTables } from "../data/tablesData"

axios.defaults.baseURL = process.env.REACT_APP_API_URL_STAGING
const loadedGameBg = { backgroundImageUrl: '/images/1.jpg' }

export default function SingleGame({ demo = false }){
    
    const params = useParams()
    const fullscreenRef = useRef()
    const navigate = useNavigate()

    const { userSession } = useUserSession()

    const { userLogged } = useUserLogged()
    const [ firIframeObj, setFirIframeObj ] = useState(null)
    const [ userVerified  ] = useState(true)

    const [ pokerTable, setPokerTable ] = useState(null)
    const [ gameDetails, setGameDetails ] = useState([]);
    
	const getPokerTable = useCallback(async () => {
        if(!params.external_id) return
        axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/casino/launch/external/'+ params.external_id +'/', {
            headers:{ "Authorization": "JWT " + localStorage.getItem('authJwt') }
        }).then((response) => {
            setPokerTable({
                items: [
                    {
                        name: 'Free Live Poker Texas Hold\'em - ' + (liveTables[response.id] ? liveTables[response.id] : onlineTables[response.id]),
                        gId: 1,
                        backgroundImageUrl: '/images/1.jpg',
                        vendor: { id: 1 },
                        launchUrl: response.data.gamelaunch_url,
                        tableData: response.data
                    }
                ]
            })
            axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/games/public/get/'+ params.external_id +'/', {
                headers:{ "Authorization": "JWT " + localStorage.getItem('authJwt') }
            }).then((response) => {
                setGameDetails(response.data);
            })
        })
    }, [params.external_id])

    useEffect(() => { getPokerTable() }, [getPokerTable])

    useEffect(() => {
        if(!pokerTable) return
        setFirIframeObj({
            name: t(pokerTable.items[0].name),
            launchUrl: pokerTable.items[0].launchUrl
        })
    }, [pokerTable])
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const receiveMessage = (event) => {
            const message = event.data;
            if(message==='mp_exitGame'){
                navigate('/', {
                    state: { scrollTo: 'poker-lobby' }
                })
            }
        };
        window.addEventListener('message', receiveMessage, false);
        return () => {
            window.removeEventListener('message', receiveMessage, false);
        };
    }, [navigate]);

    if(userLogged) {
        if(pokerTable && firIframeObj){
            return (
                <>
                    <Helmet>
                        <title>{ process.env.REACT_APP_MAIN_TITLE + (userVerified ? (pokerTable ? ' - ' + pokerTable.items[0].name + (demo ? ' (Demo Version)' : '') : '') : ' - User not verified.') }</title>
                    </Helmet>
                    <SectionSingleGame className={'section-single-game' + (loadedGameBg && ' loaded')} style={loadedGameBg && loadedGameBg} >
                            <>
                                <div id="game-windows-snap"></div>
                                <div ref={fullscreenRef} id="game-windows" className={"game-windows num-games-1" }>
                                    { !userSession && <LoadingGame /> } 
                                    {  pokerTable && !firIframeObj.gamelaunch_url && (
                                            <SingleGameBox 
                                                fullscreenRef={fullscreenRef}
                                                title={firIframeObj.name} 
                                                launchUrl={firIframeObj.launchUrl} 
                                                demo={demo} 
                                                numberBox={1} />
                                    )}
                                </div>
                                <Container className="text-center">
                                    <div className="col-12">
                                        <div className="single-gamename">
                                            <Link to="/">
                                                <div className="left-arrow"><Image src='/images/icons/icon-arrow-left.svg' /></div>
                                                <div className="game-name">
                                                    <h4>{ gameDetails ? gameDetails.table_title + ' - ' + liveTables[gameDetails.table_id] + (demo ? t(" (Demo Version)") : '') : '...' }</h4>
                                                    <div className="meta">
                                                        { gameDetails.table_small_blind && gameDetails.table_big_blind && (
                                                            <div className="item">Stake: { gameDetails.table_small_blind }$ / { gameDetails.table_big_blind }$</div>
                                                        )}
                                                        <div className="item">{ t("Min/Max Buy-In: ")}{ gameDetails.table_minimum_buy_in }$ / { gameDetails.table_maximum_buy_in }$</div>
                                                        <div className="item">{ t("Max Players: 6")}</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </Container>
                            </>
        
                    </SectionSingleGame>

                    <LivePokerLobbyList currentTable={gameDetails && gameDetails.table_id} />

                </>
            )
        }else{
            return <LoadingOutput>{ t("Loading game...")}</LoadingOutput>
        }
    } else {
        return <></>
    }

}



const SingleGameBox = ({ fullscreenRef, title, launchUrl, demo, numberBox }) => {
    return (
        <>
            <div className="multiple-box" id={"iframe-number-" + numberBox}>
                <div className="game-iframe-wrap">
                    { launchUrl && 
                        <iframe 
                        id="mainIframeFree"
                        frameBorder="0" 
                        title={title} 
                        allow="camera; microphone" 
                        src={ launchUrl + ( launchUrl.includes('?') ? '&' : '?')}></iframe>
                    }
                </div>
            </div>
        </>
    )
}


function LoadingGame(){
    return (
        <div className="single-game-loader">
            <div className="single-game-loader-info">
                <div>
                    <img alt="App loading..." src="/images/loader-slot.gif" />
                </div>
                <div>{ t("Loading game...")}</div>
            </div>
        </div>
    ) 
}

const SectionSingleGame = styled.section`
    position: relative;
    padding-top: 0px;
    background-size: cover;
    background-position: 50%;
    background-attachment: fixed;

    @media only screen and (max-width: 1199px) {
        padding: 0;
    }

    & > .container{
        position: relative;
        z-index: 945;
    }

    .single-gamename {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 1rem 0;
    
        a {
            text-decoration: none;
            display: flex;
            align-items: center;
            width: fit-content;

            @media (min-width: 0px) and (max-width: 767px){
                width: 100%;
                flex: 0 0 100%;
                text-align: left;   
            }

            .left-arrow {
                display: flex;
                align-items: center;
                img {
                    margin-right: 25px;
                    width: 14px;
                    height: auto;
                }
            }
            h4 {
                text-align: left;
                font-size: 16px;
                line-height: 22px;
                color: #ffffff;
                margin: 0;
            }

            .meta{
                display: flex;
                font-size: .75rem;
                @media (min-width: 0px) and (max-width: 767px){
                    flex-direction: column;   
                }
                .item{
                    margin-right: .5rem;
                    
                    @media (min-width: 768px){
                        &:after{
                            content: '';
                            width: 8px;
                            height: 8px;
                            border-radius: 100%;
                            background: white;
                            opacity: 0.75;
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 0.5rem;
                            position: relative;
                            top: -1px;
                        }
                    }
                }

                & > .item:last-child::after{
                    display: none;
                }

            }

        }
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            .btn {
                margin-bottom: 35px;
            }
        }
    }

    .add-game-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: #00000082;
        border-radius: 15px;
        .btn-add-game {
            max-width: 250px;
            max-height: 50px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;   
        }
        .last-game-button {
            background: none;
            position: absolute;
            bottom: -80px;
            font-size: 12px;
            top: 0;
            margin: auto;
            height: 15px;
            cursor: pointer;
            img {
                width: 13px;
                margin-left: 5px;
            }
        }
    }

    .add-game-wrapper,
    .game-iframe-wrap{
        height: calc(100vh - 100px);
        @media (min-width: 768px) and (max-width: 1399px){
            min-height: 690px;
        }
        iframe{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: 0;
        }
    }


    .single-game-iframe,
    .multiple-box{
        position: relative;
        width: 100%;
        z-index: 5;
        margin-bottom: 0px;
        display: block;
        @media (min-width: 0px) and (max-width: 991px){
            max-height: 800px;
        }
        @media (min-width: 992px){
            height: 100vh;
        }
        iframe{
            background: #000000b5;
            width: 100%;
        }
    }

    #game-windows{
        position: relative;
        z-index: 950;
    }
    

    #game-windows:fullscreen{
        background: radial-gradient(circle, #191919 0%, #141414 100%);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .single-game-search{
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            max-height: 60vh;
            overflow-y: scroll;
        }

        .multiple-box{
            align-items: center;
        }

        .game-iframe-wrap{
            width: 99%;
            padding-top: 48%;
            iframe{
                border-radius: 0.5rem;
            }
        }

        #windowSnap{
            display: none;
        }

    }

    #game-windows-snap{
        position: relative;
        top: -80px;
    }

    &.user-not-authenticated::after {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000000c7;
        z-index: 900;
    }

    .single-game-loader{
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 48%;
        background: #22202a;
        border-radius: 5px;
        border: 2px solid black;
        &-info{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img{
                width: 120px;
            }
        }
    }
`