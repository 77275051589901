import React, { useState, useEffect } from 'react'
import _uniqueId from 'lodash/uniqueId'
import { t } from 'i18next';

export default function Passwords({
    className, 
    id=_uniqueId('text-input-'), 
    theme, 
    value, 
    required, 
    setValue, 
    requestFieldChange = false, 
    password, 
    confirmPassword, 
    validatePasswords, 
    setValidatePasswords, 
    page="default" }){

    const [ requredClass, setRequredClass ] = useState("");
    const [ requredClassConfirmPassword, setRequredClassConfirmPassword ] = useState(null);
    const [ requiredMessagePasswordClass, setRequiredMessagePasswordClass ] = useState(null);
    const [ requiredMessagePassword, setRequiredMessagePassword ] = useState(null);

    useEffect(() => {
        
        if((value === null  ) && required){
            setValue('');
            //setRequredClass('required-input');
            
            setRequiredMessagePassword(t("This field is required"));
        }else if(value !== null && value !== '' && value !== undefined){
            
            setRequredClass('');
            setRequiredMessagePassword(null);
        }
    
    }, [value, setValue, required, password.password, validatePasswords])
    

    useEffect(() => {
        
        if(validatePasswords){
            
            if(!password.password){
                setRequredClass('required-input');
                setRequiredMessagePassword(t("This field is required"));
                
            }
            if(!confirmPassword.confirmPassword){
                setRequiredMessagePasswordClass("required-input");
                setRequredClassConfirmPassword(t("This field is required"));
                
            }
        }
        
        setValidatePasswords(false);
        return
    }, [validatePasswords,password.password,setValidatePasswords,confirmPassword.confirmPassword])
    
    function requiredCheck(e){
        
        
        password.setPassword(e.target.value)

        if(confirmPassword.confirmPassword !== '' && confirmPassword.confirmPassword !== null){
            
            if(confirmPassword.confirmPassword !== e.target.value){
                setRequiredMessagePasswordClass("required-input");
                setRequredClassConfirmPassword(t("Passwords doesn't match"));
            }else{
                setRequiredMessagePasswordClass("success-input");
                setRequredClassConfirmPassword("");
            }
        }

        if(e.target.value === ''){
            setRequredClass("required-input");
            setRequiredMessagePassword(t("This field is required"));
            return
        }

        if(!validatePassword(e.target.value)){
            setRequiredMessagePassword(t("Password must be at least 8 characters long contain a number and an uppercase letter"));
            setRequredClass("required-input");
            return
        }else{
            setRequredClass("");
            setRequiredMessagePassword();
        }

        
        
        if(requestFieldChange !== false){
            requestFieldChange() 
        }

            
    }

    function requiredCheckConfirm(e){
        
        //alert("A");
        
        confirmPassword.setConfirmPassword(e.target.value)

        if(e.target.value === '' ){
            setRequiredMessagePasswordClass("required-input");
            setRequredClassConfirmPassword(t("This field is required"));
            return
        }

        if(confirmPassword.confirmPassword !== ''){
            if(password.password !== e.target.value){
                setRequiredMessagePasswordClass("required-input");
                setRequredClassConfirmPassword(t("Passwords doesn't match"));
            }else{
                setRequiredMessagePasswordClass("success-input");
                setRequredClassConfirmPassword("");
            }
        }
        
        
        if(requestFieldChange !== false)
            requestFieldChange() 
        
    }

    function validatePassword(pass){
        let testStr = "^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$"

        if(!pass) return false

        if(pass.match(testStr)){
            return true
        }
        return false;
    }
    
    return (
        <>
            <div className={ 'form-row' + (className ? ' ' + className : '')}>
                <label htmlFor={id} >{ t("Password") } *</label>
                <input 
                    className={'input-text' + (theme ? ' ' + theme : '') +' '+ requredClass}
                    id={id}
                    type="password"
                    name="password"
                    autoComplete="new-password" 
                    value={ password.password!==null ? (password.password && password.password) : '' }
                    onChange={ requiredCheck }
                />
                { requiredMessagePassword && <div className="required-field-message">{requiredMessagePassword}</div> }
            </div>
            
            <div className={ 'form-row' + (className ? ' ' + className : '')}>
                <label htmlFor={'cnfrm-' + id}>{ t("Confirm Password") } *</label>
                <input 
                    className={'input-text' + (theme ? ' ' + theme : '') +' '+ requiredMessagePasswordClass}
                    id={'cnfrm-' + id}
                    type="password"
                    name="password"
                    autoComplete="new-password" 
                    value={ confirmPassword.confirmPassword!==null ? (confirmPassword.confirmPassword && confirmPassword.confirmPassword) : '' }
                    onChange={ requiredCheckConfirm }
                />
                { requredClassConfirmPassword && <div className="required-field-message">{requredClassConfirmPassword}</div> }
            </div>
        </>
         
        
    );
}