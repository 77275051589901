import React from 'react';

import { UserLoggedProvider } from './UserLoggedProvider'
import { LoginPopupProvider } from './LoginPopupProvider'
import { AppLoadedProvider } from './AppLoadedProvider'
import { UserSessionProvider } from './UserSessionProvider'
import { AppOptionsProvider } from './AppOptionsProvider'
import { UserWalletProvider } from './UserWalletProvider'
import { MenuProvider } from './MenuProvider'
import { JWTProvider } from './JWTProvider'

export default function AppProvider({ children }){
    return (

        <AppLoadedProvider>
            <UserLoggedProvider>
                <JWTProvider>
                    <AppOptionsProvider>
                        <MenuProvider>
                            <UserWalletProvider>
                                <LoginPopupProvider>
                                    <UserSessionProvider>
                                            { children }
                                    </UserSessionProvider>
                                </LoginPopupProvider>
                            </UserWalletProvider>
                        </MenuProvider>
                    </AppOptionsProvider>
                </JWTProvider>
            </UserLoggedProvider>
        </AppLoadedProvider>
    )
}