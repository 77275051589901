import React, { useState } from 'react'

/* import { BrowserView, MobileView } from 'react-device-detect'
import Navbar from '../Navbar'
import MobileHelperMenuTop from '../../../../../Navigation/MobileHelperMenu/MobileHelperMenuTop' */

import Sidebar from '../Sidebar'
import SidebarMobile from '../SidebarMobile'

const OverlayOpenProvider = ({ children }) => {

	const [overlayOpen, setOverlayOpen] = useState(false);

	return (
		<OverlayOpenContext.Provider value={{ overlayOpen, setOverlayOpen }}>
			{ children }
		</OverlayOpenContext.Provider>
	)

}

export const OverlayOpenContext = React.createContext({
	overlayOpen: false,
	setOverlayOpen: () => {}
})

export const useOverlayOpen = () => {

	const { overlayOpen, setOverlayOpen } = React.useContext(OverlayOpenContext)

	if(overlayOpen === undefined && setOverlayOpen === undefined ){
		throw new Error('useOverlayOpen was used outside its Provider!')
	}

	return { overlayOpen, setOverlayOpen }

}

const MyAccountLayoutContent = ({ children }) => {

    const { overlayOpen } = useOverlayOpen()

    return (
        <div className={"my-account-page" + (overlayOpen ? " filter-opened" : "")}>
            {/* <MobileView className="mobile">
                <MobileHelperMenuTop />
            </MobileView>
            <BrowserView>
                <Navbar />
            </BrowserView> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <Sidebar />
                        <div className="mobile-sidebar">
                            <SidebarMobile  />
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="main-content">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            {/* <MobileView className="mobile">
                <Navbar />
            </MobileView> */}
        </div>
    )
}

const MyAccountLayout = ({children}) => {

    return (
        <OverlayOpenProvider>
            <MyAccountLayoutContent children={children} />
        </OverlayOpenProvider>
    )
}

export default MyAccountLayout