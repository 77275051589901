import React, { lazy, Suspense, useState, useEffect } from 'react'
import styled from 'styled-components'

import { useAppOptions } from '../../../contexts/AppOptionsProvider'
import { useUserLogged } from '../../../contexts/UserLoggedProvider'


import './Footer.scss';
import LazyLoad from 'react-lazyload'
import { useLocation } from 'react-router-dom';

/* import TerminateAccount from './../../Authentication/Profile/TerminateAccount/TerminateAccount' */

const Copyright = lazy(() => import('./Copyright'))

const Footer = () => {

	const appOptions = useAppOptions()
    const userLogged = useUserLogged()
    const history = useLocation()
    const [ hideLogin, setHideLogin ] = useState(false)

    useEffect(() => {
        if(history.pathname.includes('login')){
            setHideLogin(true)
        }else{
            setHideLogin(false)
        }
    }, [history.pathname])

    if(hideLogin){
        return <></>
    }

	return (
		<>
			<SiteFooter className='site-footer'>
				{ appOptions && appOptions.acf && appOptions.acf.is_christmas && <SiteChristmasLights src="/images/christmas/christmas-lights.png" /> }
                <Suspense fallback={<></>}>
                    <LazyLoad height='200px' once>
                        <Copyright />
                    </LazyLoad>
                </Suspense>
			</SiteFooter>
            { 
            userLogged && userLogged.userLogged &&
            appOptions && 
            appOptions.acf && 
            appOptions.acf.technical_issues && 
            appOptions.acf.technical_issues.activate && <>
                <TechIssues>
                    <Icon src="/images/icons/icon-warning-black.svg" />
                    <span dangerouslySetInnerHTML={{ __html: appOptions.acf.technical_issues.notice }} />
                </TechIssues>
            </> }
	
		</>
	)

}

export default Footer


const SiteChristmasLights = styled.div`
    position: absolute;
    width: 100%;
    height: 20px;
    opacity: 0.8;
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-image: url(${props => props.src});
    z-index: 90;
    top: 0;
    transform: translateY(-20%);
`

const SiteFooter = styled.footer`
	position: relative;
    background-color: #0b0b0bf2;
    color: #8f949c;
    border-top: 1px solid var(--orange);
    @media (min-width: 0px) and (max-width: 767px){
        padding-top: 0px;
        padding-bottom: 80px;
    }
    a {
        color: rgb(187, 187, 187);
        text-decoration: none;
        &:hover{
            color: white;
        }
    }

    .footer-links-box {
        
        h2 {
            line-height: 35px;
            margin-bottom: 25px;
            margin-top: 0;
            @media(min-width: 1800px) {
                font-size: 30px !important;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            @media (min-width: 0px) and (max-width: 767px){
                text-align: center;   
            }
            li {
                list-style: none;
                margin-bottom: 10px;
            }
        }
    }

    .footer-copyright {
        @media (min-width: 0px) and (max-width: 767px){
            text-align: center;
            .d-flex{
                justify-content: center;
                margin-bottom: 25px;
            }
        }
        @media (min-width: 768px){
            .copyright-right{
                text-align: right;
            }
        }
        .container {
            border-top: 1px solid #ffffff24;
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 14px;
        }
	}
`

const TechIssues = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffff50;
    z-index: 99999;
    color: black;
    display: flex;
    @media (min-width: 0px) and (max-width: 767px){
        font-size: 1rem;
        line-height: 1.35rem;
        padding: 1.5rem 2rem;
        flex-direction: column;
        align-items: flex-start;
    }
    @media (min-width: 768px){
        font-size: 1.25rem;
        align-items: center;
        padding: 2rem 4rem;
    }
`

const Icon = styled.img`
    width: auto;
    @media (min-width: 0px) and (max-width: 767px){
        margin-bottom: 0.5rem;
        position: absolute;
        top: -1rem;
        left: -1rem;
        opacity: 0.1;
        height: 90px;
    }
    @media (min-width: 768px){
        margin-right: 1rem;
        height: 50px;
    }
`