import React from 'react'
import { Helmet } from 'react-helmet-async'

export default function Seo({ 
    children, 
    url = process.env.REACT_APP_URL,
    title = process.env.REACT_APP_MAIN_TITLE, 
    description = process.env.REACT_APP_MAIN_DESCRIPTION, 
    og_image = process.env.REACT_APP_OG_IMAGE }){
    return (
        <>
            <Helmet prioritizeSeoTags defer={false}>
                <title>{ title }</title>
                <meta name="description" content={ description } />
                
                <meta property="og:title" content={ title } />
                <meta property="og:description" content={ description } />
                <meta property="og:image" content={ og_image } />
                <meta property="og:image:alt" content={ title } />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ url } />
                <meta property="fb:app_id" content="3368224770086574" />
                <meta property="og:site_name" content="Live Casino - MiMa Poker | Live Dealers" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image:alt" content={ title } />

                <link rel="canonical" href={ url } />
            </Helmet>
            { children }
        </>
    )
}