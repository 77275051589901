import React from 'react'
import './TermsAndConditions.scss'
import { useParams } from 'react-router-dom'
import useGetWPAxios from '../../../api/useGetWPAxios';
import Seo from '../../General/Seo/Seo';
import LoadingOutput from '../../Output/LoadingOutput/LoadingOutput'
import { Html5Entities } from 'html-entities'
import { t } from 'i18next';
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider';
import { useLocation } from 'react-router-dom'

const TermsAndConditions = () => {

	const htmlEntities = new Html5Entities()
    const params = useParams()
    const location = useLocation()

	const { lang } = useUserLang()
	
    let page_slug = params.slug.split('/');
    page_slug = page_slug[page_slug.length-1]

    let pageFullSlug = location.pathname.split('/')[1].length===2 ? location.pathname.split('/')[2] : location.pathname.split('/')[1]
	pageFullSlug = pageFullSlug + '/' + page_slug

    const { data, error } = useGetWPAxios('wp/v2/get_post_by_lang?slug=' + pageFullSlug + '&lang=' + lang)

	
	if (!data || error) {
		return <LoadingOutput>{ t("Fetching page...")}</LoadingOutput>
	}

    return (
		<Seo 
		url={ process.env.REACT_APP_URL + 'security-privacy/' + page_slug }
		title={ process.env.REACT_APP_MAIN_TITLE + ' - ' + htmlEntities.decode(data.title) }>
			<div className="terms-and-conditions">
				<div className="main-content">
					<h1 dangerouslySetInnerHTML={{__html: data && data.post_title}} />
					<div dangerouslySetInnerHTML={{__html: data && data.content}} />
				</div>
			</div>
		</Seo>
    )
}

export default TermsAndConditions