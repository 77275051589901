import React from 'react';
import styled from 'styled-components';
import { useAppLoaded } from '../../../../../contexts/AppLoadedProvider';
import LoadingOutput from '../../../../Output/LoadingOutput/LoadingOutput';

export default function MyAccountContent({ children }){

    const { appLoaded } = useAppLoaded()

    return (
        <MyAccDivMain>
            <MyAccDiv>
                { appLoaded && children }
                { !appLoaded && <LoadingOutput>Processing request...</LoadingOutput> }
            </MyAccDiv>
        </MyAccDivMain>
    )
}

const MyAccDivMain = styled.div`
    border-bottom: 1px solid #ffffff;
`

const MyAccDiv = styled.div`
    padding-bottom: 35px;
    @media(min-width: 992px) {
        padding-bottom: 2rem;
        max-width: 850px;
    }
`