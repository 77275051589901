import React from "react"
import './ErrorOutput.scss'

import MessageOutput from '../MessageOutput'

export default function ErrorOutput({ className, children }){
    return ( 
        <MessageOutput>
            <div className={"error-output" + (className ? ' ' + className : '')}>{ children }</div>
        </MessageOutput>
    )
}