import React from "react";
import MyAccountLayout from "../components/Authentication/Profile/MyAccount/Navigation/MyAccountLayout/MyAccountLayout"
import SelfExclusion from "../components/Authentication/Profile/MyAccount/SelfExclusion/SelfExclusion";

export default function DeleteAccountPage(){
    
    return (
        <MyAccountLayout>
            <SelfExclusion />
        </MyAccountLayout>
    )
}