import React from 'react';
import './CheckboxButton.scss'

export default function CheckboxButton({children, className, theme, name, value, required, setValue}) {

    return (
        <div className={"form-row form-checkbox-input" + (className ? ' ' + className : '')}>
            <label>
                <input 
                    type="checkbox"
                    className={theme}
                    name={name}
                    defaultChecked={value}
                    value={value===null ? false : value}
                    required={required}
                    onChange={e => { setValue(e.target.checked);}}
                />
                <div className='label'>{children}</div>
                <span className='checkmark'></span>
            </label>
        </div>
    );
}