exports.locales = [{
    "code": "AF",
    "name": "Afghanistan",
    "phonePrefix": "+93",
    "currency": "AFA",
    "legalAge": 18
}, {
    "code": "AL",
    "name": "Albania",
    "phonePrefix": "+355",
    "currency": "ALL",
    "legalAge": 18
}, {
    "code": "DZ",
    "name": "Algeria",
    "phonePrefix": "+213",
    "currency": "DZD",
    "legalAge": 18
},{
    "code": "AD",
    "name": "Andorra",
    "phonePrefix": "+376",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "AO",
    "name": "Angola",
    "phonePrefix": "+244",
    "currency": "AOK",
    "legalAge": 18
}, {
    "code": "AI",
    "name": "Anguilla",
    "phonePrefix": "+1-264",
    "currency": "XCD",
    "legalAge": 18
}, {
    "code": "AQ",
    "name": "Antarctica",
    "phonePrefix": "+672",
    "currency": "",
    "legalAge": 18
}, {
    "code": "AG",
    "name": "Antigua and Barbuda",
    "phonePrefix": "+1-268",
    "currency": "XCD",
    "legalAge": 18
}, {
    "code": "AR",
    "name": "Argentina",
    "phonePrefix": "+54",
    "currency": "ARP",
    "legalAge": 18
}, {
    "code": "AM",
    "name": "Armenia",
    "phonePrefix": "+374",
    "currency": "AMD",
    "legalAge": 18
},{
    "code": "AZ",
    "name": "Azerbaijan",
    "phonePrefix": "+994",
    "currency": "AZM",
    "legalAge": 18
}, {
    "code": "BS",
    "name": "Bahamas",
    "phonePrefix": "+1-242",
    "currency": "BSD",
    "legalAge": 18
}, {
    "code": "BH",
    "name": "Bahrain",
    "phonePrefix": "+973",
    "currency": "BHD",
    "legalAge": 18
}, {
    "code": "BD",
    "name": "Bangladesh",
    "phonePrefix": "+880",
    "currency": "BDT",
    "legalAge": 18
}, {
    "code": "BB",
    "name": "Barbados",
    "phonePrefix": "+1-246",
    "currency": "BBD",
    "legalAge": 18
}, {
    "code": "BY",
    "name": "Belarus",
    "phonePrefix": "+375",
    "currency": "BYN",
    "legalAge": 18
},{
    "code": "BE",
    "name": "Belgium",
    "phonePrefix": "+32",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "BZ",
    "name": "Belize",
    "phonePrefix": "+501",
    "currency": "BZD",
    "legalAge": 18
}, {
    "code": "BJ",
    "name": "Benin",
    "phonePrefix": "+229",
    "currency": "XOF",
    "legalAge": 18
}, {
    "code": "BM",
    "name": "Bermuda",
    "phonePrefix": "+1-441",
    "currency": "BMD",
    "legalAge": 18
}, {
    "code": "BT",
    "name": "Bhutan",
    "phonePrefix": "+975",
    "currency": "INR",
    "legalAge": 18
}, {
    "code": "BO",
    "name": "Bolivia",
    "phonePrefix": "+591",
    "currency": "BOB",
    "legalAge": 18
},{
    "code": "BY",
    "name": "Bosnia and Herzegovina",
    "phonePrefix": "+387",
    "currency": "BA",
    "legalAge": 18
}, {
    "code": "BW",
    "name": "Botswana",
    "phonePrefix": "+267",
    "currency": "BWP",
    "legalAge": 18
}, {
    "code": "NOK",
    "name": "Bouvet Island",
    "phonePrefix": "+55",
    "currency": "BV",
    "legalAge": 18
},{
    "code": "BR",
    "name": "Brazil",
    "phonePrefix": "+55",
    "currency": "BRR",
    "legalAge": 18
}, {
    "code": "IO",
    "name": "British Indian Ocean Territory",
    "phonePrefix": "+246",
    "currency": "USD",
    "legalAge": 18
}, {
    "code": "BN",
    "name": "Brunei Darussalam",
    "phonePrefix": "+673",
    "currency": "BND",
    "legalAge": 18
}, {
    "code": "BG",
    "name": "Bulgaria",
    "phonePrefix": "+359",
    "currency": "BGN",
    "legalAge": 18
}, {
    "code": "BF",
    "name": "Burkina Faso",
    "phonePrefix": "+226",
    "currency": "XOF",
    "legalAge": 18
}, {
    "code": "BI",
    "name": "Burundi",
    "phonePrefix": "+257",
    "currency": "BIF",
    "legalAge": 18
}, {
    "code": "KH",
    "name": "Cambodia",
    "phonePrefix": "+855",
    "currency": "KHR",
    "legalAge": 18
}, {
    "code": "CM",
    "name": "Cameroon",
    "phonePrefix": "+237",
    "currency": "XAF",
    "legalAge": 18
}, {
    "code": "CA",
    "name": "Canada",
    "phonePrefix": "+1",
    "currency": "CAD",
    "legalAge": 18
}, {
    "code": "CV",
    "name": "Cape Verde",
    "phonePrefix": "+238",
    "currency": "CVE",
    "legalAge": 18
}, {
    "code": "KY",
    "name": "Cayman Islands",
    "phonePrefix": "+1-345",
    "currency": "KYD",
    "legalAge": 18
}, {
    "code": "CF",
    "name": "Central African Republic",
    "phonePrefix": "+236",
    "currency": "XAF",
    "legalAge": 18
}, {
    "code": "TD",
    "name": "Chad",
    "phonePrefix": "+235",
    "currency": "XAF",
    "legalAge": 18
}, {
    "code": "CL",
    "name": "Chile",
    "phonePrefix": "+56",
    "currency": "CLP",
    "legalAge": 18
}, {
    "code": "CN",
    "name": "China",
    "phonePrefix": "+86",
    "currency": "CNY",
    "legalAge": 18
}, {
    "code": "CX",
    "name": "Christmas Island",
    "phonePrefix": "+53",
    "currency": "AUD",
    "legalAge": 18
}, {
    "code": "CC",
    "name": "Cocos (Keeling) Islands",
    "phonePrefix": "+61",
    "currency": "AUD",
    "legalAge": 18
}, {
    "code": "CO",
    "name": "Colombia",
    "phonePrefix": "+57",
    "currency": "COP",
    "legalAge": 18
}, {
    "code": "BG",
    "name": "Comoros",
    "phonePrefix": "+269",
    "currency": "KM",
    "legalAge": 18
}, {
    "code": "CG",
    "name": "Congo",
    "phonePrefix": "+242",
    "currency": "XAF",
    "legalAge": 18
}, {
    "code": "CK",
    "name": "Cook Islands",
    "phonePrefix": "+682",
    "currency": "NZD",
    "legalAge": 18
}, {
    "code": "CR",
    "name": "Costa Rica",
    "phonePrefix": "+506",
    "currency": "CRC",
    "legalAge": 18
}, {
    "code": "CI",
    "name": "Cote D'Ivoire",
    "phonePrefix": "+225",
    "currency": "XOF",
    "legalAge": 18
}, {
    "code": "HR",
    "name": "Croatia",
    "phonePrefix": "+385",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "CU",
    "name": "Cuba",
    "phonePrefix": "+53",
    "currency": "CUP",
    "legalAge": 18
}, {
    "code": "CY",
    "name": "Cyprus",
    "phonePrefix": "+357",
    "currency": "CYP",
    "legalAge": 18
}, {
    "code": "CZ",
    "name": "Czech Republic",
    "phonePrefix": "+420",
    "currency": "CZK",
    "legalAge": 18
}, {
    "code": "DK",
    "name": "Denmark",
    "phonePrefix": "+45",
    "currency": "DKK",
    "legalAge": 18,
    "personalID": {
        "name": "CPR",
        "mandatory": true,
        "maxLength": 0,
        "regularExpression": ""
    }
}, {
    "code": "DJ",
    "name": "Djibouti",
    "phonePrefix": "+253",
    "currency": "DJF",
    "legalAge": 18
}, {
    "code": "DM",
    "name": "Dominica",
    "phonePrefix": "+1-767",
    "currency": "XCD",
    "legalAge": 18
}, {
    "code": "DO",
    "name": "Dominican Republic",
    "phonePrefix": "+1-829",
    "currency": "DOP",
    "legalAge": 18
}, {
    "code": "EC",
    "name": "Ecuador",
    "phonePrefix": "+593",
    "currency": "ECS",
    "legalAge": 18
}, {
    "code": "EG",
    "name": "Egypt",
    "phonePrefix": "+20",
    "currency": "EGP",
    "legalAge": 18
}, {
    "code": "SV",
    "name": "El Salvador",
    "phonePrefix": "+503",
    "currency": "SVC",
    "legalAge": 18
}, {
    "code": "GQ",
    "name": "Equatorial Guinea",
    "phonePrefix": "+240",
    "currency": "XAF",
    "legalAge": 18
}, {
    "code": "ER",
    "name": "Eritrea",
    "phonePrefix": "+291",
    "currency": "ETB",
    "legalAge": 18
}, {
    "code": "EE",
    "name": "Estonia",
    "phonePrefix": "+372",
    "currency": "EEK",
    "legalAge": 18
},{
    "code": "SZ",
    "name": "Eswatini",
    "phonePrefix": "+268",
    "currency": "SZL",
    "legalAge": 18
}, {
    "code": "ET",
    "name": "Ethiopia",
    "phonePrefix": "+251",
    "currency": "ETB",
    "legalAge": 18
}, {
    "code": "FK",
    "name": "Falkland Islands (Malvinas)",
    "phonePrefix": "+500",
    "currency": "FKP",
    "legalAge": 18
}, {
    "code": "FO",
    "name": "Faroe Islands",
    "phonePrefix": "+298",
    "currency": "DKK",
    "legalAge": 18
}, {
    "code": "FJ",
    "name": "Fiji",
    "phonePrefix": "+679",
    "currency": "FJD",
    "legalAge": 18
}, {
    "code": "FI",
    "name": "Finland",
    "phonePrefix": "+358",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "GA",
    "name": "Gabon",
    "phonePrefix": "+241",
    "currency": "XAF",
    "legalAge": 18
}, {
    "code": "GM",
    "name": "Gambia",
    "phonePrefix": "+220",
    "currency": "GMD",
    "legalAge": 18
}, {
    "code": "GE",
    "name": "Georgia",
    "phonePrefix": "+995",
    "currency": "GEL",
    "legalAge": 18,
    "personalID": {
        "name": "Personal ID Number",
        "mandatory": false,
        "maxLength": 11,
        "regularExpression": "^(\\d{11,11})$"
    }
}, {
    "code": "DE",
    "name": "Germany",
    "phonePrefix": "+49",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "GH",
    "name": "Ghana",
    "phonePrefix": "+233",
    "currency": "GHC",
    "legalAge": 18
}, {
    "code": "GI",
    "name": "Gibraltar",
    "phonePrefix": "+350",
    "currency": "GIP",
    "legalAge": 18
}, {
    "code": "GR",
    "name": "Greece",
    "phonePrefix": "+30",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "GL",
    "name": "Greenland",
    "phonePrefix": "+299",
    "currency": "DKK",
    "legalAge": 18
}, {
    "code": "GD",
    "name": "Grenada",
    "phonePrefix": "+1-473",
    "currency": "XCD",
    "legalAge": 18
},{
    "code": "GT",
    "name": "Guate",
    "phonePrefix": "+502",
    "currency": "GTQ",
    "legalAge": 18
}, {
    "code": "GN",
    "name": "Guinea",
    "phonePrefix": "+224",
    "currency": "GNF",
    "legalAge": 18
}, {
    "code": "GW",
    "name": "Guinea-Bissau",
    "phonePrefix": "+245",
    "currency": "XOF",
    "legalAge": 18
}, {
    "code": "GG",
    "name": "Guernsey",
    "phonePrefix": "+44-1481",
    "currency": "GGP",
    "legalAge": 18
}, {
    "code": "GY",
    "name": "Guyana",
    "phonePrefix": "+592",
    "currency": "GYD",
    "legalAge": 18
}, {
    "code": "HT",
    "name": "Haiti",
    "phonePrefix": "+509",
    "currency": "HTG",
    "legalAge": 18
}, {
    "code": "HM",
    "name": "Heard Island and Mcdonald Islands",
    "phonePrefix": "",
    "currency": "AUD",
    "legalAge": 18
}, {
    "code": "VA",
    "name": "Holy See (Vatican City State)",
    "phonePrefix": "+39",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "HN",
    "name": "Honduras",
    "phonePrefix": "+504",
    "currency": "HNL",
    "legalAge": 18
}, {
    "code": "HK",
    "name": "Hong Kong",
    "phonePrefix": "+852",
    "currency": "HKD",
    "legalAge": 18
}, {
    "code": "HU",
    "name": "Hungary",
    "phonePrefix": "+36",
    "currency": "HUF",
    "legalAge": 18
},{
    "code": "IS",
    "name": "Iceland",
    "phonePrefix": "+354",
    "currency": "ISK",
    "legalAge": 18
}, {
    "code": "IN",
    "name": "India",
    "phonePrefix": "+91",
    "currency": "INR",
    "legalAge": 18
}, {
    "code": "ID",
    "name": "Indonesia",
    "phonePrefix": "+62",
    "currency": "IDR",
    "legalAge": 18
},{
    "code": "IR",
    "name": "Iran, Islamic Republic of",
    "phonePrefix": "+98",
    "currency": "IRR",
    "legalAge": 18
}, {
    "code": "IQ",
    "name": "Iraq",
    "phonePrefix": "+964",
    "currency": "IQD",
    "legalAge": 18
},{
    "code": "IE",
    "name": "Ireland",
    "phonePrefix": "+353",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "IL",
    "name": "Israel",
    "phonePrefix": "+972",
    "currency": "ILS",
    "legalAge": 18
},{
    "code": "IM",
    "name": "Isle of Man",
    "phonePrefix": "+44",
    "currency": "IMP",
    "legalAge": 18
}, {
    "code": "JM",
    "name": "Jamaica",
    "phonePrefix": "+1-876",
    "currency": "JMD",
    "legalAge": 18
}, {
    "code": "JP",
    "name": "Japan",
    "phonePrefix": "+81",
    "currency": "JPY",
    "legalAge": 18
}, {
    "code": "JE",
    "name": "Jersey",
    "phonePrefix": "+44",
    "currency": "JEP",
    "legalAge": 18
},{
    "code": "JO",
    "name": "Jordan",
    "phonePrefix": "+962",
    "currency": "JOD",
    "legalAge": 18
}, {
    "code": "KZ",
    "name": "Kazakhstan",
    "phonePrefix": "+7",
    "currency": "KZT",
    "legalAge": 18
}, {
    "code": "KE",
    "name": "Kenya",
    "phonePrefix": "+254",
    "currency": "KES",
    "legalAge": 18
}, {
    "code": "KI",
    "name": "Kiribati",
    "phonePrefix": "+686",
    "currency": "AUD",
    "legalAge": 18
}, {
    "code": "KP",
    "name": "Korea, Democratic People's Republic of Korea",
    "phonePrefix": "+850",
    "currency": "KPW",
    "legalAge": 18
}, {
    "code": "KR",
    "name": "Korea, Republic of Korea",
    "phonePrefix": "",
    "currency": "KRW",
    "legalAge": 18
}, {
    "code": "XK",
    "name": "Kosovo",
    "phonePrefix": "+383",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "KW",
    "name": "Kuwait",
    "phonePrefix": "+965",
    "currency": "KWD",
    "legalAge": 18
}, {
    "code": "KG",
    "name": "Kyrgyzstan",
    "phonePrefix": "+996",
    "currency": "KGS",
    "legalAge": 18
}, {
    "code": "LA",
    "name": "Lao People's Democratic Republic",
    "phonePrefix": "+856",
    "currency": "LAK",
    "legalAge": 18
}, {
    "code": "LVL",
    "name": "Latvia",
    "phonePrefix": "+371",
    "currency": "LV",
    "legalAge": 18
},{
    "code": "LB",
    "name": "Lebanon",
    "phonePrefix": "+961",
    "currency": "LBP",
    "legalAge": 18
}, {
    "code": "LS",
    "name": "Lesotho",
    "phonePrefix": "+266",
    "currency": "LSL",
    "legalAge": 18
}, {
    "code": "LR",
    "name": "Liberia",
    "phonePrefix": "+231",
    "currency": "LRD",
    "legalAge": 18
}, {
    "code": "LY",
    "name": "Libyan Arab Jamahiriya",
    "phonePrefix": "+218",
    "currency": "LYD",
    "legalAge": 18
}, {
    "code": "LI",
    "name": "Liechtenstein",
    "phonePrefix": "+423",
    "currency": "CHF",
    "legalAge": 18
}, {
    "code": "LVL",
    "name": "Lithuania",
    "phonePrefix": "+370",
    "currency": "EUR",
    "legalAge": 18
},{
    "code": "LU",
    "name": "Luxembourg",
    "phonePrefix": "+352",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "MO",
    "name": "Macao",
    "phonePrefix": "+853",
    "currency": "MOP",
    "legalAge": 18
}, {
    "code": "MK",
    "name": "Macedonia",
    "phonePrefix": "+389",
    "currency": "MKD",
    "legalAge": 18
}, {
    "code": "MG",
    "name": "Madagascar",
    "phonePrefix": "+261",
    "currency": "MGF",
    "legalAge": 18
}, {
    "code": "MW",
    "name": "Malawi",
    "phonePrefix": "+265",
    "currency": "MWK",
    "legalAge": 18
}, {
    "code": "MV",
    "name": "Maldives",
    "phonePrefix": "+960",
    "currency": "MVR",
    "legalAge": 18
}, {
    "code": "ML",
    "name": "Mali",
    "phonePrefix": "+223",
    "currency": "XOF",
    "legalAge": 18
}, {
    "code": "MT",
    "name": "Malta",
    "phonePrefix": "+356",
    "currency": "MTL",
    "legalAge": 18
}, {
    "code": "MH",
    "name": "Marshall Islands",
    "phonePrefix": "+692",
    "currency": "USD",
    "legalAge": 18
}, {
    "code": "MQ",
    "name": "Martinique",
    "phonePrefix": "+596",
    "currency": "EUR",
    "legalAge": 18
},{
    "code": "MR",
    "name": "Mauritania",
    "phonePrefix": "",
    "currency": "MRO",
    "legalAge": 18
}, {
    "code": "MU",
    "name": "Mauritius",
    "phonePrefix": "+230",
    "currency": "MUR",
    "legalAge": 18
},{
    "code": "YT",
    "name": "Mayotte",
    "phonePrefix": "+262",
    "currency": "EUR",
    "legalAge": 18
},{
    "code": "MX",
    "name": "Mexico",
    "phonePrefix": "+52",
    "currency": "MXP",
    "legalAge": 18
}, {
    "code": "FM",
    "name": "Micronesia, Federated States of",
    "phonePrefix": "+691",
    "currency": "USD",
    "legalAge": 18
}, {
    "code": "MC",
    "name": "Monaco",
    "phonePrefix": "+377",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "MN",
    "name": "Mongolia",
    "phonePrefix": "+976",
    "currency": "MNT",
    "legalAge": 18
}, {
    "code": "ME",
    "name": "Montenegro",
    "phonePrefix": "+382",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "MS",
    "name": "Montserrat",
    "phonePrefix": "+1-664",
    "currency": "XCD",
    "legalAge": 18
},{
    "code": "MD",
    "name": "Moldova",
    "phonePrefix": "+373",
    "currency": "MDA",
    "legalAge": 18
},{
    "code": "MA",
    "name": "Morocco",
    "phonePrefix": "+212",
    "currency": "MAD",
    "legalAge": 18
}, {
    "code": "MZ",
    "name": "Mozambique",
    "phonePrefix": "+258",
    "currency": "MZM",
    "legalAge": 18
}, {
    "code": "MM",
    "name": "Myanmar",
    "phonePrefix": "+95",
    "currency": "MMK",
    "legalAge": 18
}, {
    "code": "NA",
    "name": "Namibia",
    "phonePrefix": "+264",
    "currency": "NAD",
    "legalAge": 18
}, {
    "code": "NR",
    "name": "Nauru",
    "phonePrefix": "+674",
    "currency": "AUD",
    "legalAge": 18
}, {
    "code": "NP",
    "name": "Nepal",
    "phonePrefix": "+977",
    "currency": "NPR",
    "legalAge": 18
},{
    "code": "NC",
    "name": "New Caledonia",
    "phonePrefix": "+687",
    "currency": "CFP",
    "legalAge": 18
},{
    "code": "NZ",
    "name": "New Zealand",
    "phonePrefix": "+64",
    "currency": "NZD",
    "legalAge": 18
},{
    "code": "NI",
    "name": "Nicaragua",
    "phonePrefix": "+505",
    "currency": "NIO",
    "legalAge": 18
}, {
    "code": "NE",
    "name": "Niger",
    "phonePrefix": "+227",
    "currency": "XOF",
    "legalAge": 18
}, {
    "code": "NG",
    "name": "Nigeria",
    "phonePrefix": "+234",
    "currency": "NGN",
    "legalAge": 18
}, {
    "code": "NU",
    "name": "Niue",
    "phonePrefix": "+683",
    "currency": "NZD",
    "legalAge": 18
}, {
    "code": "NF",
    "name": "Norfolk Island",
    "phonePrefix": "+672",
    "currency": "AUD",
    "legalAge": 18
},{
    "code": "NO",
    "name": "Norway",
    "phonePrefix": "+47",
    "currency": "NOK",
    "legalAge": 18
}, {
    "code": "OM",
    "name": "Oman",
    "phonePrefix": "+968",
    "currency": "OMR",
    "legalAge": 18
}, {
    "code": "PW",
    "name": "Palau",
    "phonePrefix": "+680",
    "currency": "USD",
    "legalAge": 18
},{
    "code": "PK",
    "name": "Pakistan",
    "phonePrefix": "+92",
    "currency": "PKR",
    "legalAge": 18
},{
    "code": "PA",
    "name": "Panama",
    "phonePrefix": "+507",
    "currency": "PAB",
    "legalAge": 18
}, {
    "code": "PG",
    "name": "Papua New Guinea",
    "phonePrefix": "+675",
    "currency": "PGK",
    "legalAge": 18
}, {
    "code": "PY",
    "name": "Paraguay",
    "phonePrefix": "+595",
    "currency": "PYG",
    "legalAge": 18
}, {
    "code": "PE",
    "name": "Peru",
    "phonePrefix": "+51",
    "currency": "PEN",
    "legalAge": 18
}, {
    "code": "PH",
    "name": "Philippines",
    "phonePrefix": "+63",
    "currency": "PHP",
    "legalAge": 18
}, {
    "code": "PN",
    "name": "Pitcairn",
    "phonePrefix": "+872",
    "currency": "NZD",
    "legalAge": 18
}, {
    "code": "PL",
    "name": "Poland",
    "phonePrefix": "+48",
    "currency": "PLN",
    "legalAge": 18
},{
    "code": "PT",
    "name": "Portugal",
    "phonePrefix": "+351",
    "currency": "EUR",
    "legalAge": 18
},{
    "code": "QA",
    "name": "Qatar",
    "phonePrefix": "+974",
    "currency": "QAR",
    "legalAge": 18
}, {
    "code": "RO",
    "name": "Romania",
    "phonePrefix": "+40",
    "currency": "RON",
    "legalAge": 18
}, {
    "code": "RU",
    "name": "Russia",
    "phonePrefix": "+7",
    "currency": "RUB",
    "legalAge": 18
}, {
    "code": "RW",
    "name": "Rwanda",
    "phonePrefix": "+250",
    "currency": "RWF",
    "legalAge": 18
}, {
    "code": "SH",
    "name": "Saint Helena",
    "phonePrefix": "",
    "currency": "",
    "legalAge": 18
}, {
    "code": "KN",
    "name": "Saint Kitts and Nevis",
    "phonePrefix": "+1-869",
    "currency": "XCD",
    "legalAge": 18
}, {
    "code": "LC",
    "name": "Saint Lucia",
    "phonePrefix": "+1-758",
    "currency": "XCD",
    "legalAge": 18
}, {
    "code": "VC",
    "name": "Saint Vincent and the Grenadines",
    "phonePrefix": "+1-784",
    "currency": "XCD",
    "legalAge": 18
},{
    "code": "WS",
    "name": "Samoa",
    "phonePrefix": "+684",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "SM",
    "name": "San Marino",
    "phonePrefix": "+378",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "ST",
    "name": "Sao Tome and Principe",
    "phonePrefix": "",
    "currency": "STD",
    "legalAge": 18
}, {
    "code": "SA",
    "name": "Saudi Arabia",
    "phonePrefix": "+966",
    "currency": "SAR",
    "legalAge": 18
}, {
    "code": "SN",
    "name": "Senegal",
    "phonePrefix": "+221",
    "currency": "XOF",
    "legalAge": 18
}, {
    "code": "RS",
    "name": "Serbia",
    "phonePrefix": "+381",
    "currency": "RSD",
    "legalAge": 18
}, {
    "code": "SC",
    "name": "Seychelles",
    "phonePrefix": "+248",
    "currency": "SCR",
    "legalAge": 18
}, {
    "code": "SL",
    "name": "Sierra Leone",
    "phonePrefix": "+232",
    "currency": "SLL",
    "legalAge": 18
}, {
    "code": "SG",
    "name": "Singapore",
    "phonePrefix": "+421",
    "currency": "EUR",
    "legalAge": 18
}, {
    "code": "SK",
    "name": "Slovakia",
    "phonePrefix": "+65",
    "currency": "SGD",
    "legalAge": 18
},{
    "code": "SI",
    "name": "Slovenia",
    "phonePrefix": "+386",
    "currency": "EUR",
    "legalAge": 18
},{
    "code": "SB",
    "name": "Solomon Islands",
    "phonePrefix": "+677",
    "currency": "SBD",
    "legalAge": 18
}, {
    "code": "SO",
    "name": "Somalia",
    "phonePrefix": "+252",
    "currency": "SOS",
    "legalAge": 18
}, {
    "code": "ZA",
    "name": "South Africa",
    "phonePrefix": "+27",
    "currency": "ZAR",
    "legalAge": 18
}, {
    "code": "GS",
    "name": "South Georgia and the South Sandwich Islands",
    "phonePrefix": "+82",
    "currency": "GBP",
    "legalAge": 18
}, {
    "code": "LK",
    "name": "Sri Lanka",
    "phonePrefix": "+94",
    "currency": "LKR",
    "legalAge": 18
}, {
    "code": "SD",
    "name": "Sudan",
    "phonePrefix": "+249",
    "currency": "SDD",
    "legalAge": 18
}, {
    "code": "SR",
    "name": "Suriname",
    "phonePrefix": "+597",
    "currency": "SRG",
    "legalAge": 18
}, {
    "code": "SJ",
    "name": "Svalbard and Jan Mayen",
    "phonePrefix": "+47",
    "currency": "NOK",
    "legalAge": 18
}, {
    "code": "SZ",
    "name": "Swaziland",
    "phonePrefix": "+268",
    "currency": "SZL",
    "legalAge": 18
},{
    "code": "SE",
    "name": "Sweden",
    "phonePrefix": "+46",
    "currency": "SEK",
    "legalAge": 18,
    "personalID": {
        "name": "Personal ID Number",
        "mandatory": true,
        "maxLength": 15,
        "regularExpression": "^(([\\d]{6,6})|([\\d]{8,8}))([-]|[\\s]{1,1}){0,1}([\\d]{4,4})$"
    }
}, {
    "code": "CH",
    "name": "Switzerland",
    "phonePrefix": "+41",
    "currency": "CHF",
    "legalAge": 18
}, {
    "code": "SY",
    "name": "Syrian Arab Republic",
    "phonePrefix": "+963",
    "currency": "SYP",
    "legalAge": 18
}, {
    "code": "TW",
    "name": "Taiwan, Province of China",
    "phonePrefix": "+886",
    "currency": "TWD",
    "legalAge": 18
}, {
    "code": "TJ",
    "name": "Tajikistan",
    "phonePrefix": "+992",
    "currency": "TJR",
    "legalAge": 18
}, {
    "code": "TZ",
    "name": "Tanzania, United Republic of",
    "phonePrefix": "+255",
    "currency": "TZS",
    "legalAge": 18
},{
    "code": "TH",
    "name": "Thailand",
    "phonePrefix": "+66",
    "currency": "THB",
    "legalAge": 18
},{
    "code": "TG",
    "name": "Togo",
    "phonePrefix": "+228",
    "currency": "XOF",
    "legalAge": 18
}, {
    "code": "TK",
    "name": "Tokelau",
    "phonePrefix": "+690",
    "currency": "NZD",
    "legalAge": 18
}, {
    "code": "TO",
    "name": "Tonga",
    "phonePrefix": "+676",
    "currency": "TOP",
    "legalAge": 18
},{
    "code": "TL",
    "name": "Timor-leste",
    "phonePrefix": "+670",
    "currency": "THB",
    "legalAge": 18
},{
    "code": "TT",
    "name": "Trinidad and Tobago",
    "phonePrefix": "+1-868",
    "currency": "TTD",
    "legalAge": 18
}, {
    "code": "TN",
    "name": "Tunisia",
    "phonePrefix": "+216",
    "currency": "TND",
    "legalAge": 18
}, {
    "code": "TM",
    "name": "Turkmenistan",
    "phonePrefix": "+993",
    "currency": "TMM",
    "legalAge": 18
}, {
    "code": "TC",
    "name": "Turks and Caicos Islands",
    "phonePrefix": "+1-649",
    "currency": "USD",
    "legalAge": 18
},{
    "code": "TL",
    "name": "Turkey",
    "phonePrefix": "+90",
    "currency": "TRY",
    "legalAge": 18
},{
    "code": "TV",
    "name": "Tuvalu",
    "phonePrefix": "+688",
    "currency": "AUD",
    "legalAge": 18
}, {
    "code": "UG",
    "name": "Uganda",
    "phonePrefix": "+256",
    "currency": "UGX",
    "legalAge": 18
}, {
    "code": "AE",
    "name": "United Arab Emirates",
    "phonePrefix": "+971",
    "currency": "AED",
    "legalAge": 18
},{
    "code": "UA",
    "name": "Ukraine",
    "phonePrefix": "+380",
    "currency": "UAH",
    "legalAge": 18
},{
    "code": "UY",
    "name": "Uruguay",
    "phonePrefix": "+598",
    "currency": "UYU",
    "legalAge": 18
}, {
    "code": "UZ",
    "name": "Uzbekistan",
    "phonePrefix": "+998",
    "currency": "UZS",
    "legalAge": 18
}, {
    "code": "VU",
    "name": "Vanuatu",
    "phonePrefix": "+678",
    "currency": "VUV",
    "legalAge": 18
}, {
    "code": "VE",
    "name": "Venezuela",
    "phonePrefix": "+58",
    "currency": "VEB",
    "legalAge": 18
}, {
    "code": "VN",
    "name": "Viet Nam",
    "phonePrefix": "+84",
    "currency": "VND",
    "legalAge": 18
},{
    "code": "WF",
    "name": "Wallis and Futuna",
    "phonePrefix": "+681",
    "currency": "CFP",
    "legalAge": 18
},{
    "code": "EH",
    "name": "Western Sahara",
    "phonePrefix": "+212",
    "currency": "MAD",
    "legalAge": 18
},{
    "code": "YE",
    "name": "Yemen",
    "phonePrefix": "+967",
    "currency": "YER",
    "legalAge": 18
}, {
    "code": "ZM",
    "name": "Zambia",
    "phonePrefix": "+260",
    "currency": "ZMK",
    "legalAge": 18
}, {
    "code": "ZW",
    "name": "Zimbabwe",
    "phonePrefix": "+263",
    "currency": "ZWD",
    "legalAge": 18
}]