import React, { useState } from "react"

export const AppLoadedContext = React.createContext({
	appLoaded: false,
	setAppLoaded: () => {},
})

export const AppMessageContext = React.createContext({
	appMessage: false,
	setAppMessage: () => {},
})

export const AppLoadedProvider = ({ children }) => {

	const [appLoaded, setAppLoaded] = useState(false);
	const [appMessage, setAppMessage] = useState('');

	return (
		<AppLoadedContext.Provider value={{ appLoaded, setAppLoaded }}>
			<AppMessageContext.Provider value={{ appMessage, setAppMessage }}>
				{ children }
			</AppMessageContext.Provider>
		</AppLoadedContext.Provider>
	)

}

export const useAppLoaded = () => {

	const { appLoaded, setAppLoaded } = React.useContext(AppLoadedContext)

	if(appLoaded === undefined && setAppLoaded === undefined ){
		throw new Error('useAppLoaded was used outside its Provider!')
	}

	return { appLoaded, setAppLoaded }

}

export const useAppMessage = () => {

	const { appMessage, setAppMessage } = React.useContext(AppMessageContext)

	if(appMessage === undefined && setAppMessage === undefined ){
		throw new Error('useAppMessage was used outside its Provider!')
	}

	return { appMessage, setAppMessage }

}


