import React from "react"
import './SuccessOutput.scss'

import MessageOutput from '../MessageOutput'

export default function SuccessOutput({ children }){
    return ( 
        <MessageOutput>
            <div className="success-output" dangerouslySetInnerHTML={{ __html: children }} />
        </MessageOutput>
    )
}