let liveTables = []
//realy money
liveTables[1]       = 'Area 52'
liveTables[101]       = 'Area 52'
liveTables[2]       = 'The Poker Matrix'
liveTables[102]       = 'The Poker Matrix'
liveTables[103]       = 'Poker Temple'
liveTables[3]       = 'Royal Flush Lounge'
liveTables[301]       = 'Royal Flush Lounge'
liveTables[4]       = 'Bluffing Bonanza'
liveTables[5]       = 'Full House Frenzy'
liveTables[6]       = 'All-In Arena'
liveTables[7]       = 'The Final Table'
liveTables[8]       = 'The Chip Chateau'
liveTables[9]       = 'Ace\'s Haven'
liveTables[10]      = 'Poker Paradiso'
liveTables[11]      = 'The Virtual Vault'
liveTables[12]      = 'Shuffle & Showdown'
liveTables[13]      = 'The Bluffing Club'
liveTables[14]      = 'Chip City'
liveTables[15]      = 'Ace\'s Cyber Deck'
liveTables[16]      = 'High Stakes Hideout'
liveTables[17]      = 'The River\'s Edge'
liveTables[18]      = 'Pocket Rockets Club'
liveTables[19]      = 'House of Cards'


let onlineTables = []
onlineTables[2]     = 'Virtual Flush'
onlineTables[3]     = 'Poker Reality'

export { liveTables, onlineTables }