import React from 'react';

export default function Container({ className='', children }){

    return (
        <div className={"container" + (className && ' ' + className)}>
            <div className="row">
                {/* <div className="col"> */}
                    { children }
                {/* </div> */}
            </div>
        </div>
    )
}