import React, { useState } from "react"

export const LoginPopupContext = React.createContext({
	loginPopup: false,
	setLoginPopup: () => {}
})

export const QuickRegisterPopupContext = React.createContext({
	quickRegisterPopup: false,
	setQuickRegisterPopup: () => {}
})

export const ChangePasswordPopup = React.createContext({
	changePasswordPopup: false,
	setChangePasswordPopup: () => {}
})

export const LoginPopupProvider = ({ children }) => {

	const [loginPopup, setLoginPopup] = useState(false);
	const [quickRegisterPopup, setQuickRegisterPopup] = useState(false);
	const [ changePasswordPopup, setChangePasswordPopup] = useState(false);

	return (
		<QuickRegisterPopupContext.Provider value={{ quickRegisterPopup, setQuickRegisterPopup }}>
			<LoginPopupContext.Provider value={{ loginPopup, setLoginPopup }}>
				<ChangePasswordPopup.Provider value={{changePasswordPopup, setChangePasswordPopup}}>
					{ children }
				</ChangePasswordPopup.Provider>
			</LoginPopupContext.Provider>
		</QuickRegisterPopupContext.Provider>
	)

}

export const useLoginPopup = () => {

	const { loginPopup, setLoginPopup } = React.useContext(LoginPopupContext)

	if(loginPopup === undefined && setLoginPopup === undefined ){
		throw new Error('useLoginPopup was used outside its Provider!')
	}

	return { loginPopup, setLoginPopup }

}

export const useQuickRegisterPopup = () => {

	const { quickRegisterPopup, setQuickRegisterPopup } = React.useContext(QuickRegisterPopupContext)

	if(quickRegisterPopup === undefined && setQuickRegisterPopup === undefined ){
		throw new Error('useQuickRegisterPopup was used outside its Provider!')
	}

	return { quickRegisterPopup, setQuickRegisterPopup }

}

export const useChangePasswordPopup = () => {
	
	const { changePasswordPopup, setChangePasswordPopup } = React.useContext(ChangePasswordPopup)

	if(changePasswordPopup === undefined && setChangePasswordPopup === undefined ){
		throw new Error('setChangePasswordPopup was used outside its Provider!')
	}

	return { changePasswordPopup, setChangePasswordPopup }
}