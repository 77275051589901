import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { useUserLogged } from "../../../contexts/UserLoggedProvider"
import { useUserSession } from '../../../contexts/UserSessionProvider'

import ErrorOutput from '../../Output/ErrorOutput/ErrorOutput'
import SuccessOutput from '../../Output/SuccessOutput/SuccessOutput'
import LoadingOutput from '../../Output/LoadingOutput/LoadingOutput'
import TextInput from '../../Utils/TextInput/TextInput'
import SubmitButton from '../../Utils/SubmitButton/SubmitButton'
import Image from '../../Utils/Image/Image'

import './LoginForm.scss'
import { isMobile } from 'react-device-detect'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha"
import SMSActivationModal from '../SMSActivationModal/SMSActivationModal'
import { t } from 'i18next'

export default function LoginForm({ returnPath='/' }){

    const location = useLocation()
    const navigate = useNavigate()
    const { setUserSession } = useUserSession()

    const [ accountSmsActivated, setAccountSmsActivated ] = useState(null)
    const [ openSMSModal, setOpenSMSModal ] = useState(true)

    const [ mobilePhoneMumber, setMobilePhoneMumber ]  = useState(null)
    const [ smsCode, setSmsCode ]  = useState(null)
    const [ email, setEmail ] = useState(null)

    //const { changePasswordPopup } = useChangePasswordPopup()
    const [password, setPassword] = useState(null)
    const [togglePassword, setTogglePassword] = useState(false)
    
    const [errorResponse, setErrorResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    
    // const { lang } = useUserLang()
    const { userLogged, setUserLogged } = useUserLogged()
    
    const [usernameOrEmail, setUsernameOrEmail] = useState('')

    const recaptchaRef = useRef(null);
    const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // change to your site key
    const [ recaptchaResponse, setRecaptchaResponse ] = useState();
    const v2Callback = (token, refreshToken) => {
        setRecaptchaResponse(token)
    }

    useEffect(() => {
        if(userLogged){
            setTimeout(() => {
                if(location.pathname==='/login'){
                    navigate(returnPath)
                }
            }, 1250)
        } 
    }, [location.pathname, navigate, userLogged, returnPath])

    const handleSubmit = async e => {
        e.preventDefault();

        let parameters = {
            username: usernameOrEmail,
            password: password,
            recaptcha:recaptchaResponse
        }

        if((usernameOrEmail === null || usernameOrEmail === "") && (password === null || password === "")){
            setErrorResponse(t("Username or E-mail and Password cannot be empty"))
            return;
        }

        if(usernameOrEmail === null || usernameOrEmail === ""){
            setErrorResponse(t("Username or E-mail cannot be empty"))
            return;
        }

        if(password === null || password === ""){
            setErrorResponse(t("Password cannot be empty"))
            return;
        }
        
        setIsLoading(true)
        setErrorResponse(null)

        if(!recaptchaResponse){
            setErrorResponse(t("Please complete the reCaptcha process."))
        }

        
        axios.post(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/auth/v2/login/', parameters)
        .then((response) => {
            setPassword('')
            setUsernameOrEmail('')
            setUserLogged(true)
            setIsLoading(false)
            localStorage.setItem('authJwt',response.data.token)

            axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/accounts/profile/profile-data/', {
                headers: { "Authorization": "JWT " + localStorage.getItem('authJwt') }
            })
            .then((response) => {
                setUserLogged(true)
                setUserSession(response.data)
            })

        }).catch(function (error) {
            let error_response = ''
            if(error.response.data.code === "account_not_active"){
                setEmail(error.response.data.profile.email)
                setMobilePhoneMumber(error.response.data.profile.mobile_phone_number)
                setAccountSmsActivated(false)
                setOpenSMSModal(true)
            }else{
                setErrorResponse(error.response.data.details+error_response);
                setIsLoading(false)
            }
            recaptchaRef.current?.reset();
        })
        
    }

    return (
        <>
        
        { accountSmsActivated===false && 
            <SMSActivationModal 
                email={email} 
                smsCode={smsCode} 
                openSMSModal={openSMSModal} 
                setUserLogged={setUserLogged} 
                password={password} 
                setPassword={setPassword} 
                setUserSession={setUserSession} 
                setSmsCode={setSmsCode} 
                mobilePhoneMumber={mobilePhoneMumber} 
                accountSmsActivated={accountSmsActivated} 
                setAccountSmsActivated={setAccountSmsActivated} /> }

        
            <div className="login-container form-section">
                <Link to="/">
                    <Image src='/images/mobile-assets/logo.svg' className="login-logo"/>
                </Link>
                <form onSubmit={handleSubmit}>
                    <TextInput 
                        autoFocus={!isMobile} 
                        required={true} 
                        placeholder={t("Username or E-mail")}
                        type="text" 
                        setValue={setUsernameOrEmail} 
                        inputmode="email" />

                    <div className="password-wrap">
                        <TextInput 
                        required={true} 
                        placeholder="Password" 
                        type={togglePassword ? "text" : "password"} 
                        setValue={setPassword}  />
                        <img className="btn-pwd-toggle" onClick={() => { setTogglePassword(!togglePassword) }} alt="Toggle Password" src={"/images/icons/icon-eye-"+ (togglePassword ? 'closed' : 'open') +".svg"} />
                    </div>
                
                    <Link to="/forgot-password" className='forgot-password d-block mt-1'>{ t("Forgot Password") }</Link>

                    <div id="recaptcha-badge">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={sitekey}
                            theme={'dark'}
                            hl='en'
                            onChange={v2Callback}
                            tabindex={0}
                        />
                    </div>
                    
                    <SubmitButton theme="light">{ t("Login") }</SubmitButton>

                    <p className='signup'>{ t("Don't have an account?") } <span onClick={() => { 
                        setErrorResponse('')
                        navigate('/register')
                    }}>{ t("Sign Up") }</span></p>
                    
                    { isLoading && <LoadingOutput>Logging in...</LoadingOutput> }
                    { !userLogged && returnPath && returnPath!=='/' && !errorResponse && !isLoading && <ErrorOutput>{ t("You need to login first") }...</ErrorOutput> }
                    { errorResponse && <ErrorOutput>{ errorResponse }</ErrorOutput> }
                    { userLogged && !isLoading && !errorResponse && <SuccessOutput >{ t("Logged in successfully") }.</SuccessOutput> }
                    
                    
                </form>
            </div>
            

            {/* { changePasswordPopup && <ChangePasswordPopupForm changePasswordPopup={changePasswordPopup} usernameOrEmail={usernameOrEmail} /> } */}

        </>
        
    )
}

