import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next';

const TableSelection = ({ open }) => {

    const motionWrapperOpen = { opacity: 1 }
    const motionWrapperClosed = { opacity: 0 }
    const navigate = useNavigate()

    const [pokerTables, setPokerTables] = useState(null)
	
	const getPokerTables = useCallback(async () => {
        //https://mima-casino-api.stage.norway.everymatrix.com/v1/casino/games/46387095991?language=en
		let response = await axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/public/games/external/all/')
        response = await response.data
        console.log(response)
		setPokerTables(response)
    }, [])

    useEffect(() => { getPokerTables() }, [getPokerTables])

    const table1 =  (t("House of Cards"))
    const table9 = ( t("Area 52"))

	return (
        <AnimatePresence>
            { open && <TableSelectionWrap
                initial={motionWrapperClosed} 
                animate={motionWrapperOpen} 
                exit={motionWrapperClosed} 
                transition={{ type: "linear", stiffness: 550 }}>
                <h4>{ t("Choose Table:")}</h4>
                <TablesRow>
                    { pokerTables && pokerTables.map((table, index) => {
                        if(table.details && !table.details.table_demo_mode) return <React.Fragment key={index}></React.Fragment>
                        return (
                            <TableButton key={index} onClick={() => { navigate('/game/free-poker_mima/play/free/' + table.external_id) }}>
                                {table.id===1 && table1}
                                {table.id===9 && table9}
                            </TableButton>
                        )
                    }) }
                </TablesRow>
            </TableSelectionWrap> }
        </AnimatePresence>
	)
}

export default TableSelection

const TableSelectionWrap = styled(motion.div)`
    position: absolute;
    bottom: 0px;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    padding: 15px;
    min-width: 300px;
    background: linear-gradient(var(--orange), var(--orange-hover));
    z-index: 900;
    border-radius: 5px;
    border: 2px solid var(--orange-hover);
    box-shadow: 0px 5px 35px black;
    h4{
        margin: 0;
        margin-bottom: 0.5rem;
    }
`

const TablesRow = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`

const TableButton = styled.span`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding: 15px 5px;
    margin: 5px 2px;
    border: 2px solid white;
    border-radius: 35px;
    font-size: 12px;
    font-weight: bold;
    flex: 1 0 auto;
    background: linear-gradient(to bottom, var(--orange) , var(--orange-hover));
    &:hover{
        background: #6e390b;
    }
`