import React, { useEffect, useState, useCallback} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import styled, { css } from 'styled-components'
import Countdown from 'react-countdown'

import { liveTables } from '../../../data/tablesData'
import { useAppOptions } from '../../../contexts/AppOptionsProvider'
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider'
import { useUserSession } from '../../../contexts/UserSessionProvider'
import moment from 'moment'
import 'moment-timezone'

const formatter = new Intl.NumberFormat('en-US')
const countdownTables = [21]
const videoTables = [21]

/* const utcHours = new Date().getUTCHours()
const privatePlayers1 = Math.floor(utcHours / 10) + 4
const privatePlayers2 = Math.floor(utcHours / 10) + 3
const privatePlayers3 = Math.floor(utcHours / 10) + 2

const privateRealTables = [
    {
        tableName: 'Royal Flush',
        private: true,
        table_id: 1011,
        table_auto_mode: false,
        table_small_blind: 5,
        table_big_blind: 10,
        table_minimum_buy_in: 1000,
        table_maximum_buy_in: 10000,
        table_demo_mode: false,
        table_dealer_name: 'Kira',
        table_live_players: privatePlayers1
    },
    {
        tableName: 'Feels Like Vegas',
        private: true,
        table_id: 1022,
        table_auto_mode: false,
        table_small_blind: 10,
        table_big_blind: 20,
        table_minimum_buy_in: 500,
        table_maximum_buy_in: 5000,
        table_demo_mode: false,
        table_dealer_name: 'Leah',
        table_live_players: privatePlayers2
    },
] */
const privateRealTables = []

export default function LivePokerLobbyList({ currentTable=null, hide=false, innerRef, setLobbyRefWrap }){

    const appOptions = useAppOptions()
    const { userSession } = useUserSession()
    /* eslint-disable */
    const [stakeOrder, setStakeOrder] = useState('asc')
    const [filteredAllGames, setFilteredAllGames] = useState()
    const [allGames, setAllGames] = useState([])
    const [casinoGames, setCasinoGames] = useState([])
    const [countDealerGames, setCountDealerGames] = useState(1)
    const bannedPlayers = appOptions && appOptions.acf && appOptions.acf.banned_players ? appOptions.acf.banned_players : []
    
    /* eslint-disable */

    const getCasinoGames = useCallback(async () => {
        let response = await axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/casino/core/mapping/retrieve/all/?p=1&page_size=24&date_from=2022-01-01&date_to=2032-01-01', {
            headers:{
                "Authorization": "JWT " + localStorage.getItem('authJwt')
            }
        })
        response = await response.data
        if(response.results && response.results.length>0){
            setCasinoGames(response.results)
        }
    }, [])
    
	const getPokerGames = useCallback(async () => {
		
        let response = await axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/games/public/all/')
        response = await response.data
        
        let countDealerTables = 0
        let fetchAllGames = []
        fetchAllGames = [...response, ...privateRealTables]
        
        for(let i = 0; i<fetchAllGames.length; i++){
            //if(fetchAllGames[i] && fetchAllGames[i].table_auto_mode) continue
            fetchAllGames[i].table_small_blind = parseFloat(fetchAllGames[i].table_small_blind)
            fetchAllGames[i].table_big_blind = parseFloat(fetchAllGames[i].table_big_blind)
            
            if(!fetchAllGames[i].table_auto_mode){
                countDealerTables++
            }
        }

        setCountDealerGames(countDealerTables)
        setAllGames(sortByStake(fetchAllGames, stakeOrder))

    }, [])

    const sortByStake = (games = [], order = 'asc') => {
        const sortedData = games.sort(function(a, b){
            if(order==='desc'){
                return a.table_auto_mode - b.table_auto_mode || b.table_big_blind - a.table_big_blind;
            }else{
                return a.table_auto_mode - b.table_auto_mode || a.table_big_blind - b.table_big_blind;
            }
        })
        return sortedData
    }


    useEffect(() => {
        let games = []
        // games = allGames
        // games = allGames.filter(function(item) { return item.table_demo_mode !== true })
        games = allGames.filter(function(item) { return item.table_demo_mode === true })
        // games = games.filter(function(item) { return item.table_code !== 'plo4' })
        setFilteredAllGames(sortByStake(games, stakeOrder))
    }, [allGames])
    /* eslint-disable */

    useEffect(() => { getPokerGames() }, [getPokerGames])
    useEffect(() => { getCasinoGames() }, [getCasinoGames])

    useEffect(() => { if(innerRef && innerRef.current){ setLobbyRefWrap(innerRef.current) } }, [innerRef, setLobbyRefWrap])
    useEffect(() => { 
        const interval = setInterval(() => { getPokerGames() }, 30000);
        return () => clearInterval(interval);
    }, [getPokerGames]);

    if(hide){ return <></> }

    return (
        <LobbySection>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-11">
                        <TableTypeSection>
                            {/* <Heading>
                                <Title>
                                    {   appOptions && 
                                        appOptions.acf.poker_tables_lobby && 
                                        appOptions.acf.poker_tables_lobby.live_tables && 
                                        <h3 dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.title }} /> }
                                </Title>
                                <div className='iconDesc'> 
                                {   appOptions && 
                                        appOptions.acf.poker_tables_lobby && 
                                        appOptions.acf.poker_tables_lobby.live_tables && 
                                        <p dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.description }} /> }
                                </div>
                            </Heading> */}
                            <LiveTableImage src={"/images/mobile-assets/livetable.svg"} />
                            <TableListRow>
                                { filteredAllGames && filteredAllGames.map((item, index) => {
                                    if(userSession){
                                        const isBanned = bannedPlayers.some(player => parseInt(player.table_id) === item.table_id && player.username === userSession.username);
                                        if (isBanned) return <React.Fragment key={index}></React.Fragment>
                                    }

                                    if(item && item.table_id === 2) return <React.Fragment key={index}></React.Fragment>
                                    //if(item && item.table_auto_mode) return <React.Fragment key={index}></React.Fragment>
                                        return (
                                            <TableList 
                                                key={index}
                                                name={liveTables[item.table_id] ? liveTables[item.table_id] : item.tableName} 
                                                type={item.table_demo_mode ? "online" : "live"}
                                                tableType={item.table_auto_mode ? 'auto' : 'dealer'} 
                                                free={item.table_demo_mode} 
                                                iscurrent={currentTable===item.table_id ? 1 : 0} 
                                                table={item} 
                                                dealerColumns={countDealerGames.toString()} 
                                                privateTable={item.private} />
                                        )
                                    }) 
                                }
                            </TableListRow>
                        </TableTypeSection>
                    </div>
                </div>
            </div>
        </LobbySection>
    )
}

const TableList = ({ 
    name = "Poker Table", 
    type='live', 
    tableType='dealer', 
    table, 
    iscurrent = 0, 
    free = true, 
    privateTable = false,
    dealerColumns }) => {

    const [todayAtNoonCET, setTodayAtNoonCET] = useState(null)
    const [tableOpen, setTableOpen] = useState(true)
    const [dealerPhoto, setDealerPhoto] = useState(null)
    const { lang } = useUserLang()
    let toLink = privateTable ? '' : (iscurrent===0 ? "/game/live-poker_mima/play"+ (free ? "/free/" : "/") + table.table_game_id : "")
    toLink = lang==='en' ? toLink : "/" + lang + toLink

    const isWebcamFeature = videoTables.includes(table.table_id)
    const isLiveDealerTable = tableType==='dealer'
    const isAutoTable = tableType==='auto' && !videoTables.includes(table.table_id)


    useEffect(() => {
        const now = moment()
        moment.tz.setDefault('Europe/Paris')
        const isBetween12And20 = now && now.isBetween(now.clone().startOf('day').add(12, 'hours'), now.clone().startOf('day').add(23, 'hours').add(59, 'minutes').add(59, 'seconds'))
        if(countdownTables.includes(table.table_id)){
            setTableOpen(isBetween12And20)
        }
        const todayAtNoon = moment().startOf('day').add(12, 'hours')
        setTodayAtNoonCET(todayAtNoon)
    }, [table])

    useEffect(() => {
        try {
            const imageUrl = "/images/dealers/" + table.table_dealer_name.replace('_', '').replace(/\s/g, '') + ".png";
            const image = new Image();
            image.src = imageUrl;
            image.onload = () => {
                setDealerPhoto(imageUrl);
            };
            image.onerror = () => {
                setDealerPhoto("/images/dealers/no-dealer-photo.png");
            };
        } catch (err) {
            setDealerPhoto("/images/dealers/no-dealer-photo.png");
        }
    }, [table])

    return (
        <PokerTableList
            as={!privateTable && tableOpen ? Link : 'div'}
            iscurrent={iscurrent}
            type={type} 
            dealercolumns={dealerColumns} 
            isdealer={isLiveDealerTable ? 'true' : 'false'}
            id={table.table_id}
            to={ toLink }
            style={{  
                border: isLiveDealerTable && '1px solid rgba(255,187,77,1)'
            }}
            >
                
            <TableContainer>
                <TableLeftPart>
                    {isLiveDealerTable ? <TableBackground style={{  
                                    backgroundImage: "url(/images/mobile-assets/dealer-back.svg)"
                                }} /> : <></> }
                    <TableImage>
                        { isLiveDealerTable && table.table_dealer_name && (
                            <DealerPhotoWrap>
                                { tableOpen && <TableArtImage className='live-dealer-image' style={{  
                                    backgroundImage: "url("+dealerPhoto+")"
                                }}/> }
                                { !tableOpen && <TableArtImage style={{  
                                    backgroundImage: "url(/images/dealers/no-dealer-photo.png)"
                                }}/> }
                            </DealerPhotoWrap>
                        )}
                      
                        {/* Masi so racno staveni sliki */}
                         { isAutoTable && name === "Area 52" && <TableArtImage className='virtual-table-image' style={{  
                            backgroundImage: "url(/images/mobile-assets/area52.svg)",
                        }}/> }
                         { isAutoTable && name === "The Poker Matrix" && <TableArtImage className='virtual-table-image' style={{  
                            backgroundImage: "url(/images/mobile-assets/thepokermatrix.svg)",
                        }}/> }
                        { isAutoTable && name === "Poker Temple" && <TableArtImage className='virtual-table-image' style={{  
                            backgroundImage: "url(/images/mobile-assets/pokertemple.svg)",
                        }}/> }
                          { isAutoTable && <TableArtImage className='virtual-table-image' style={{  
                            backgroundImage: "url(/images/mobile-assets/logo.svg)",
                            backgroundSize: '100px'
                        }}/>  }
                    </TableImage>
                    <TableStreamDesc style={{  
                        border: isLiveDealerTable && '1px solid rgba(255,187,77,1)'
                    }}>
                        { isLiveDealerTable && table.table_dealer_name && <p className='table-stream-type'><b>Live</b> Table</p> }
                        { isAutoTable && <p className='table-stream-type'><b>Virtual</b> Table</p> }
                    </TableStreamDesc>
                    { tableOpen && table.table_dealer_name.replace('_', '') !== "Dealer" ? <DealerName><b style={{  
                color: isLiveDealerTable && 'rgba(255,187,77,1)'
            }}>Dealer:</b><span>{ table.table_dealer_name.replace('_', '') }</span></DealerName> : <></>}
                    { tableOpen && table.table_dealer_name.replace('_', '') !== "Dealer" && isWebcamFeature && <TableLiveIconContainerCam><TableLiveIcon src="/images/mobile-assets/camera.svg"/></TableLiveIconContainerCam>}
                    { tableOpen && table.table_dealer_name.replace('_', '') !== "Dealer" &&  <TableChatIconContainerLive><TableLiveIcon src="/images/mobile-assets/chat.svg"/></TableChatIconContainerLive>}
                    { !tableOpen && todayAtNoonCET && countdownTables.includes(table.table_id) && (
                        <CountdownWrap>
                            <div>Opens in:</div>
                            <Countdown date={todayAtNoonCET.format()} />
                        </CountdownWrap>
                    )}
                </TableLeftPart>

                <TableRightPart>
                    <TableDivider src="/images/mobile-assets/divider.svg"/>
                    <TableName>
                        { isLiveDealerTable && table.table_dealer_name && <p className='table-main-name' style={{  
                            color: isLiveDealerTable && 'rgba(255,187,77,1)'
                        }}><b>Live</b> { name }</p> }
                        { isAutoTable && <p className='table-main-name'>{ name }</p> }
                    </TableName>
                    <TableImageCards src="/images/mobile-assets/cards-icon.svg" />
                    <TableInfo>
                        <TableInfoField>
                            <span style={{  
                                color: isLiveDealerTable && 'rgba(255,187,77,1)'
                            }}>Stake:</span> { table.table_small_blind && table.table_big_blind && (
                                    <div>{ table.table_small_blind }$ / { table.table_big_blind }$</div>
                                )}
                        </TableInfoField>
                        <TableInfoField>
                            <span style={{  
                                color: isLiveDealerTable && 'rgba(255,187,77,1)'
                            }}>Players:</span> { table.table_live_players } / 6
                        </TableInfoField>
                        <TableInfoField>
                            <span style={{  
                                color: isLiveDealerTable && 'rgba(255,187,77,1)'
                            }}>Buyin:</span> { formatter.format((table.table_minimum_buy_in * 100/ 100).toFixed(2)) }$ / { formatter.format((table.table_maximum_buy_in * 100/ 100).toFixed(2)) }$
                        </TableInfoField>
                    </TableInfo>
                </TableRightPart>
                { tableOpen && iscurrent!==1 && !privateTable && (
                    <JoinWrap style={{  
                        border: isLiveDealerTable && '1px solid rgba(255,187,77,1)'
                    }}/>
                )}
            </TableContainer>

        </PokerTableList>
    )
}




const LobbySection = styled.section`
    padding: 4rem 0;
    margin: 0;
    background-size: cover;
    background-position: center;
    @media (min-width: 0px) and (max-width: 767px){
        margin-top: 0.5rem;
        border-top: 0px;
        padding: 14px 0px 16px 0px;
    }
    .table-container-dealer {
        border: 1px solid rgba(255, 187, 77, 1)!important;
    }
`


const PokerTableList = styled(Link)`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    margin: 0;
    border-radius: 16px;
    text-decoration: none;
    width: 100%;
    flex: 0 0 100%;
    padding: 0px;
    background: black;
    border: 1px solid rgba(0, 255, 255, 1);
    box-shadow: none;
    min-height: 103px;
`

const TableContainer = styled.div`
   width: 100%;
    display: flex;
    justify-content: space-between;
`

const TableLeftPart = styled.div`
   position: relative;
   width: 40%;
   border-radius: 16px;
    overflow: hidden;
`

const TableRightPart = styled.div`
    width: 60%;
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 15px;
`

const LiveTableImage = styled.img`
   margin-top: 15px;
    width: 100%;
`

const TableImage = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
`

const TableBackground = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-position: center;
    background-size: cover;
`

const TableArtImage = styled.div`
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`

const TableStreamDesc = styled.div`
    position: absolute;
    top: -1;
    left: 0px;
    width: 96px;
    height: 16px;
    border: 1px solid rgba(0, 255, 255, 1);
    border-bottom-right-radius: 48px;
    border-top-left-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    .table-stream-type {
        margin: 0px;
        font-size: 9px;
        line-height: normal;
        text-transform: uppercase;
        b {
            font-weight: 500;
        }
    }
`
const JoinWrap = styled.div`
    position: absolute;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -12px;
    top: calc(50% - 0.75rem);
    background: black;
    border-radius: 50%;
    border: 1px solid rgba(0, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid white;
        left: 7.5px;
    }
`

const DealerPhotoWrap = styled.div`
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .live-dealer-image {
        background-position: bottom;
        background-size: 105px;
        background-position-x: 13px;
    }
`

const DealerName = styled.div`
    position: absolute;
    left: 0.35rem;
    bottom: 0.35rem;
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 0.5rem;
    z-index: 9;
    display: flex;
    flex-direction: column;
    left: 7px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 94.17%);
    width: 80%;
    height: 35px;
    bottom: 0px;
    left: 0px;
    padding: 4px 7px;
    b {
        font-size: 9px;
        margin: 0px;
        font-weight: bold;
        color: rgba(0, 255, 255, 1);
    }
    span{
        font-size: 12px;
    }
`

const TableLiveIconContainerCam= styled.div`
    position: absolute;
    top: 65px;
    right: 0;
    width: 32px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 12.5px;
    border-bottom-left-radius: 12.5px;
`

const TableChatIconContainerLive = styled.div`
    position: absolute;
    top: 30px;
    right: 0;
    width: 32px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 12.5px;
    border-bottom-left-radius: 12.5px;
`

const TableLiveIcon = styled.img`
   
`

const TableDivider = styled.img`
   position: absolute;
    left: 0px;
    height: 100%;
`


const TableName = styled.div`
   .table-main-name {
        margin: 0px;
        font-size: 20px;
        color: rgba(0, 255, 255, 1);
        b {
            text-transform: uppercase;
            color: white;
        }
   }
`

const TableImageCards = styled.img`
   padding-left: 2px;
`

const TableInfo = styled.div`
    margin-top: 10px;
`

const TableInfoField = styled.div`
    display: flex;
    align-items: center;
   span {
        font-size: 12px;
        font-weight: bold;
        color: rgba(0, 255, 255, 1);
        margin-right: 5px;
   }
   b {
    font-size: 15px;
    font-weight: 500;
   }
`

const TableChatIconContainer = styled.div`

   
`




const TableTypeSection = styled.div`
    h3{
        @media (min-width: 0px) and (max-width: 767px){
            font-size: 1.875rem;
        }
        @media (min-width: 768px){
            font-size: 2.5rem;
        }
        margin: 0;
    }
`


const liveCss = css`
    background: linear-gradient(135deg, var(--casino) 0%, var(--orange) 100%);
    &:hover{
        background: var(--orange);
    }
`

const onlineCss = css`
    background: linear-gradient(135deg, var(--default) 0%, var(--casino) 100%);
    &:hover{
        background: var(--default);
    }
`

const JoinButton = styled.img`
    width: 100%;
    height: auto;
`



const TableListRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 15px;
`




const CameraImageContainer = styled.div`
   
`

const CameraImage = styled.img`
   
`

const DealerPhoto = styled.img`
    width: 95%;
    max-width: 126px;
    height: auto;
    float: left;
`



const Heading = styled.div`
    margin-top: 0rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 0px) and (max-width: 767px){
        flex-direction: row;
        align-items: flex-start;
    }
    @media (min-width: 768px){
        display: flex;
    }
    h3{
        font-size: 2rem;
        max-width: 200px;
    }
    p{
        margin: 0;
    }
`

const Title = styled.div`
    color: var(--orange);
`

const PrivateOnly = styled.div`
    padding: 0.5rem 0;
`

const TableFilters = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.35rem;
        @media (min-width: 0px) and (max-width: 767px){
            justify-content: space-between;
            margin-bottom: 0.5rem;
        }
`

const FilterOption = styled.label`
    margin-left: 1rem;
    border-radius: 0.5rem;
    border: 1px solid white;
    padding: 0.75rem 1rem;
    padding-left: 0.75rem;
    cursor: pointer;
    transition: all .2s ease-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 10px;
    input{
        margin: 0;
        margin-right: 0.5rem;
    }
    &:hover{
        background: gray;
    }
    @media (min-width: 0px) and (max-width: 767px){
        font-size: 14px;
        flex: 0 0 calc(33.3334% - 6px);
        margin: 0;        
    }
`

const TableDesktopLabel = styled.span`
    @media (min-width: 0px) and (max-width: 767px){
        display: none;
    }
    @media (min-width: 768px){
        display: block;
    }
`
const TableMobileLabel = styled.span`
    @media (min-width: 0px) and (max-width: 767px){
        display: block;
    }
    @media (min-width: 768px){
        display: none;
    }
`

const TableTypeIcon = styled.img`
    width: auto;
    height: 3rem;
    margin-right: 0.5rem;
    @media (min-width: 768px){
        margin-right: 1rem;
        margin-left: 0.5rem;
    }
`

const WebcamIcon = styled(TableTypeIcon)`
    position: absolute;
    z-index: 9;
    top: -12px;
    left: -20px;
    background: #752e2b;
    border: 1px solid white;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    padding: 0.5rem;
`

const CountdownWrap = styled.div`
    & > span{
        @media (min-width: 0px) and (max-width: 1199px){
            font-size: 0.95rem;
        }
        @media (min-width: 1200px){
            font-size: 1rem;
        }
        font-weight: bold;
    }
`