import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMenuProvider } from '../../../../../contexts/MenuProvider'
import Button from '../../../../Utils/Button/Button'

import Image from '../../../../Utils/Image/Image'

const SidebarMobile = () => {
    const sidebarItems = useMenuProvider()

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const openDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <div className="sidebar-mobile">
            <Button className="sidebar-title" onClickEvent={openDropdown}>
                {sidebarItems.map(( item, index ) => {
                    if(item.to === window.location.pathname) {
                        return (
                            <div key={index} className="current-item">
                                <div className="name">
                                    {item.name}
                                </div>
                                <Image alt={"Open Dropdown for " + item.name} src='/images/icons/shevron-white.svg'/>
                            </div>
                        )
                    }
                    else {
                        return null
                    }
                })}
            </Button>
            <div className={`dropdown` + (dropdownOpen ? ' open' : '')}>
                {sidebarItems.map(( item, index ) => {
                    return (
                        <Link key={index} to={item.to}>
                            <Image alt={item.name} src={item.image}/>
                            {item.name}
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

export default SidebarMobile