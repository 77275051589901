import React from "react";
import MyAccountLayout from "../../components/Authentication/Profile/MyAccount/Navigation/MyAccountLayout/MyAccountLayout"
import ChangePhoneNumber from "../../components/Authentication/Profile/MyAccount/ChangePhoneNumber/ChangePhoneNumber"


export default function ChangePhoneNumberPage(){

    return(
        <MyAccountLayout>
            <ChangePhoneNumber />
        </MyAccountLayout>
    )
}