import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { LangSwitcherProvider } from './contexts/LanguageSwitcherProvider';

if (process.env.REACT_APP_ENV === 'production' && window.location.hostname !== 'localhost') {
	console.log = () => {}
	console.error = () => {}
	console.debug = () => {}
}

console.log('Env is set to: ' + process.env.REACT_APP_ENV)

ReactDOM.render(
	<React.Fragment>
		<LangSwitcherProvider>
			<App />
		</LangSwitcherProvider>
	</React.Fragment>,
	document.getElementById('root')
);
