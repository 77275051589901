import React from 'react';
import Button from '../Button/Button';

import './SubmitButton.scss'

export default function SubmitButton({children, theme}) {
    return (
        <div className="form-submit">
            <Button type="submit" className={'btn' + (theme ? ' ' + theme : '')}>{ children }</Button>
        </div>
    );
}