import React from 'react'
import { useParams, useLocation } from 'react-router-dom';
import useGetWPAxios from '../../../api/useGetWPAxios';
import styled from 'styled-components';
import LoadingOutput from '../../Output/LoadingOutput/LoadingOutput';
import Seo from '../../General/Seo/Seo'
import { Html5Entities } from 'html-entities'
import { t } from 'i18next';

import { useUserLang } from '../../../contexts/LanguageSwitcherProvider'

const Guide = () => {

	const htmlEntities = new Html5Entities()
    const params = useParams()
    const location = useLocation()

	const { lang } = useUserLang()
	
    let page_slug = params.slug.split('/');
    page_slug = page_slug[page_slug.length-1]

    let pageFullSlug = location.pathname.split('/')[1].length===2 ? location.pathname.split('/')[2] : location.pathname.split('/')[1]
	pageFullSlug = pageFullSlug + '/' + page_slug

    const { data, error } = useGetWPAxios('wp/v2/get_post_by_lang?slug=' + pageFullSlug + '&lang=' + lang)

	if (!data || error) {
		return <LoadingOutput>{ t("Fetching page...")}</LoadingOutput>
	}

    return (
		<Seo 
		url={ process.env.REACT_APP_URL + 'security-privacy/' + page_slug }
		title={ process.env.REACT_APP_MAIN_TITLE + ' - ' + htmlEntities.decode(data.post_title) }>
			<Page>
				<MainContent>
					<h1 dangerouslySetInnerHTML={{__html: data && data.post_title}} />
					<div dangerouslySetInnerHTML={{__html: data && data.content}} />
				</MainContent>
			</Page>
		</Seo>
    )
}

export default Guide


const Page = styled.div`
	margin: 30px 0;
	color: #ffffff;
`

const MainContent = styled.div`
	padding-top: 20px;
	@media(min-width: 992px) {
		padding-left: 40px;
	}

	h1 {
		padding-bottom: 30px;
		border-bottom: 1px solid #ffffff;
		margin-bottom: 33px;
	}

	ul{
		font-weight: 300;
		li{
			margin-bottom: 5px;
		}
	}

	img{
		max-width: 100%;
		height: auto;
	}

	.wp-video{
		&, video{
			width: 100% !important;
		}
	}

`