import React from 'react'
import styled from 'styled-components'

export default function Spacer({size=25}){
	return <SpacerElement size={size} />
}

const SpacerElement = styled.div`
	width: 100%;
	height: ${props => props.size}px;
`