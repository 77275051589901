import React from "react"
import './LoadingOutput.scss'

import MessageOutput from '../MessageOutput'

export default function LoadingOutput({ className, children }){
    return (
        <MessageOutput>
            <div className={"loading-output" + (className ? ' ' + className : '')}>{ children }</div>
        </MessageOutput>
    )
}