import React, { useState } from "react"

export const UserWalletContext = React.createContext({
	userWallet: 0,
	setUserWallet: () => {}
})

export const UserWalletProvider = ({ children }) => {

    const [ userWallet, setUserWallet ] = useState({
		amount:0,
		bonusAmount:0,
		currency: 'USD'
	})

	return (
		<UserWalletContext.Provider value={{ userWallet, setUserWallet }}>
			{ children }
		</UserWalletContext.Provider>
	)

}

export const useUserWalletProvider = () => {
	
	const { userWallet, setUserWallet } = React.useContext(UserWalletContext)
	
	if(userWallet === undefined ){
		throw new Error('useUserWallet was used outside its Provider!')
	}
	

	return { userWallet, setUserWallet };
 
}


