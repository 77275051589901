import React, {useState} from "react"
import axios from 'axios'

import "react-datepicker/dist/react-datepicker.css"

import ErrorOutput from '../../../../Output/ErrorOutput/ErrorOutput'
import SuccessOutput from '../../../../Output/SuccessOutput/SuccessOutput'
import LoadingOutput from "../../../../Output/LoadingOutput/LoadingOutput"

import Button from "../../../../Utils/Button/Button"
import Image from "../../../../Utils/Image/Image"
import MyAccountContent from "../Navigation/MyAccountContent"

import '../Bonuses/Bonuses.scss'

import { t } from 'i18next'
import { useUserSession } from "../../../../../contexts/UserSessionProvider"
import { useUserLogged } from "../../../../../contexts/UserLoggedProvider"

export default function SelfExclusion() {

    const { setUserSession } = useUserSession()
    const { setUserLogged } = useUserLogged()

    const [confirmPopup, setConfirmPopup] = useState(false);

    const [errorResponse, setErrorResponse] = useState(null)
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)

    const userLogout = () => {
        localStorage.removeItem('authJwt')
        setUserSession(null)
        setUserLogged(false)
    }

    const handleCoolOffSubmit = async e => {
        setErrorResponse(null)
        setConfirmPopup(false)
        setLoading(true)

        const axiosCall = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/accounts/profile/deactivate-account/',
            headers: { 
                'Authorization': 'JWT ' + localStorage.getItem('authJwt'),
                'Content-Type': 'application/json'
            },
        }

        axios.request(axiosCall).then((response) =>{
            console.log(response)
            setResponse(response)
            setLoading(false)
            setTimeout(() => {
                userLogout()
            }, 3000)
        }).catch((error) => {
            setLoading(false)
            setErrorResponse(error.response.data.details)
        })
    }

    return (
        <div className="profile-settings">
            <div className="top-bar">
                <h1>{ t("Delete Account") }</h1>
            </div>
            <div
                className='bonus-legal-popup'
                style={{
                display: (confirmPopup
                    ? 'flex'
                    : 'none')
            }}>
                <h2>{ t("Are you sure you want to delete account?") }</h2>
                <div className='bonus-legal-popup-butons'>
                    <Button onClickEvent={handleCoolOffSubmit}>{ t("YES")}</Button>
                    <Button
                        secondary
                        onClickEvent={() => {
                        setConfirmPopup(false)
                    }}>{ t("NO")}</Button>
                </div>
                <p className='legal-popup-exit' id='legal-popup-exit' onClick={() => { setConfirmPopup(false) }}>
                    <Image src="/images/icons/icon-close.svg"></Image>
                </p>
            </div>
            <form >
                <MyAccountContent>
                    <div className="mt-2">
                        <h4>If you want to delete your account please click on the button and confirm.</h4>
                        <Button onClickEvent={() => { setConfirmPopup(true) }}>{ t("Submit")}</Button>
                    </div>
                    {loading && <LoadingOutput>{ t("Processing...")}</LoadingOutput>}
                    {errorResponse && <ErrorOutput>{errorResponse}</ErrorOutput>}
                    {response && !errorResponse && <SuccessOutput >{ response }<br/>Logging out...</SuccessOutput>}
                </MyAccountContent>
            </form>
        </div>
    )
}
