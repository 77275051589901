import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useGetWPAxios from '../../../api/useGetWPAxios'
import styled from 'styled-components'
import { t } from 'i18next'
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider'

const menuIds = {
	en: 12,
	es: 35,
	ru: 81
}

const GuideSidebar = () => {

    const { lang } = useUserLang()
    const { data, error } = useGetWPAxios('wp/v2/menu?menu_id=' + menuIds[lang])

    const location = useLocation()
    const [activeCat, setActiveCat] = useState('')

    useEffect(() => {
        let pathWithoutDomain = '/' + location.pathname.split('/').slice(1).join('/')
        pathWithoutDomain = pathWithoutDomain.replaceAll('/' + lang, '')
        setActiveCat(pathWithoutDomain)
    }, [lang, location])
  

    return (
        <SidebarWrap>

            { error && <p>{ t("Error loading items...")}</p> }

            <ul>
                { data && data.map((item, index) => {
                    return(
                        <li key={index} className={item.en === activeCat ? 'active' : ''}>
                            <Link to={(lang !== 'en' ? '/' + lang : '') + item.en} className="link"><span dangerouslySetInnerHTML={{ __html: item.name }} /></Link>
                        </li>
                    )
                }) }
            </ul>


        </SidebarWrap>
    )
}

export default GuideSidebar

const SidebarWrap = styled.div`
    margin-top: 25px;
    background-color: #000000;
    padding: 0;
    display: none;
    position: sticky;
    top: 120px;
    @media(min-width: 992px) {
        display: block;
    }

    ul {
        margin: 0;
        padding: 0;
        .active {
            background-color: #131313;
            border-left: 4px solid #2AA9D8;
        }
        li {
            list-style: none;
            background-color: transparent;
            transition: .3s all ease-in-out;
            border-left: 4px solid #243042;
            
            &:hover {
                background-color: #131313;
                border-left: 4px solid #2AA9D8;
            }
            
            a {
                padding: 15px 25px;
                text-decoration: none;
                color: #ffffff;
                display: flex;
                align-items: center;
                
                img {
                    margin-right: 20px;
                    width: 22px;
                }
            }
        }
    }
`