import React from "react"
import styled from "styled-components";
import Guide from "../components/Help/Guide/Guide";
import GuideSidebar from "../components/Navigation/GuideSidebar/GuideSidebar";

const GuidePage = () => {
    return (
        <GuidePageWrap>
            <div className='container'>
                <div className="row">
                    <div className="col-lg-9 order-lg-2">
                        <div className="content">
                            <Guide />
                        </div>
                    </div>
                    <div className="col-lg-3 order-lg-1">
                        <GuideSidebar />
                    </div>
                </div>
            </div>
        </GuidePageWrap>
    )
}

export default GuidePage

const GuidePageWrap = styled.div`

    .table-wrap{
        width: 100%;
        @media (max-width: 1199px){
            overflow-x: scroll;
        }
    }

    table{
        min-width: 750px;
        width: 100%;
        table-layout: fixed;
        background: #101010;
        box-shadow: 0px 15px 25px black;
        &, p{
            font-size: 0.875rem;
            line-height: 1.25rem;
        }

        p{
            margin: 0.25rem 0;
        }

        tr{
            border: 1px solid #707070;
            td{
                padding: 0.5rem 1rem;
                border-right: 1px solid #707070;
                border-bottom: 1px solid #707070;
            }
            td:first-child{
                width: 120px;
                img{
                    object-fit: contain;
                    height: 50px;
                }
            }
            td:last-child{
                border-right: none;
            }
            &:nth-child(odd){
                td{
                    background: #1e1e1e;
                }
            }
            &:first-child{
                td{
                    background-color: var(--default-hover);
                    &:first-child{
                        border-top-left-radius: 0.9rem;
                    }
                    &:last-child{
                        border-top-right-radius: 0.9rem;
                    }
                }
            }
            &:last-child{
                td{
                    border-bottom: none;
                }
            }
        }

    }
`