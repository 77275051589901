import React from "react"
import { isWebpSupported } from "react-image-webp/dist/utils"
import useGetWPAxios from "../api/useGetWPAxios"
import { useUserLang } from "./LanguageSwitcherProvider"

export const AppOptionsContext = React.createContext([])
export const AppMainBannerContext = React.createContext([])
export const AppColorThemeContext = React.createContext([])

export const AppOptionsProvider = ({ children }) => {

	const { lang } = useUserLang()
	const { data } = useGetWPAxios('acf/v3/options/options' + (lang !== 'en' ? `?lang=${lang}` : ''))

	return (
		<AppOptionsContext.Provider value={ data && data }>
			<AppBannerProvider>
				<AppColorThemeProvider>
					{ children }
				</AppColorThemeProvider>
			</AppBannerProvider>
		</AppOptionsContext.Provider>
	)

}

const AppBannerProvider = ({ children }) => {
	
	const { lang } = useUserLang()
	const { data } = useGetWPAxios('wp/v2/get_post_by_lang?slug=homepage&lang=' + lang)

	return (
		<AppMainBannerContext.Provider value={ data && data.acf }>
			{ children }
		</AppMainBannerContext.Provider>
	)
}

const AppColorThemeProvider = ({ children }) => {

	const colorTheme = 'orange'

	return (
		<AppColorThemeContext.Provider value={colorTheme}>
			{ children }
		</AppColorThemeContext.Provider>
	)
}

export const useAppOptions = () => {
	const appOptions = React.useContext(AppOptionsContext)
	if(appOptions === undefined ) return
	return appOptions
}

export const useAppMainBanner = () => {
	const appBanner = React.useContext(AppMainBannerContext)
	if(appBanner === undefined ) return
	return appBanner
}

export const useAppColorTheme = () => {
	const appColorTheme = React.useContext(AppColorThemeContext)
	if(appColorTheme === undefined ) return
	return appColorTheme
}

export const useDefaultAppBannerStyle = () => {
	const appOptions = React.useContext(AppOptionsContext)
	const defaultBanner = appOptions && appOptions.acf && appOptions.acf.default_banner && appOptions.acf.default_banner.url
    const defaultBannerStyle = { backgroundImage: 'url("'+ defaultBanner + (isWebpSupported ? '.webp' : '') +'")' }
	if(!appOptions) return
	if(!defaultBanner) return
	return defaultBannerStyle
}