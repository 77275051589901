import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { Link, useLocation } from 'react-router-dom'
import { useUserLogged } from '../../../contexts/UserLoggedProvider'

function MobileBottomBar() {

    const { userLogged } = useUserLogged()
    const history = useLocation()
    const [ openSocial, setOpenSocial ] = useState(false)
    const [ inGame, setInGame ] = useState(false)

    useEffect(() => {
        setInGame(history.pathname.includes('game/live-poker_mima'))
    }, [history.pathname])
    console.log(inGame, history.pathname)

    if(!userLogged) return <></>

	return (
		<MobileBottomBarContainer isInGame={inGame ? '1' : '0'} id="mobile-bottom-bar">
            <MobileBottomBarBack />
            <BottomBarBox className='dock-promotions' >
                <BottomBarBoxImage src='/images/mobile-assets/promotions-cs.svg' />
                <BottomBarBoxTitle style={{
                    color: '#00FF85',
                }}>
                    Promotions
                </BottomBarBoxTitle>
            </BottomBarBox>
            <BottomBarBox className='dock-tournaments' >
                <BottomBarBoxImage src='/images/mobile-assets/tournaments-cs.svg' />
                <BottomBarBoxTitle style={{
                    color: '#00FFFF',
                }}>
                    Tournaments
                </BottomBarBoxTitle>
            </BottomBarBox>
            <BottomBarBox className='dock-lobby dock-active'>
                <Link title="lobby" alt="lobby" to={'/'}><BottomBarBoxImage src='/images/mobile-assets/lobby.svg' /></Link>
                <BottomBarBoxTitle>
                    Lobby
                </BottomBarBoxTitle>
                <BottomBarBoxComingSoon style={{
                        display: 'none'
                }}>
                    Coming Soon
                </BottomBarBoxComingSoon>
            </BottomBarBox>
            <BottomBarBox className='dock-social'>
                <BottomBarBoxImage src='/images/mobile-assets/social.svg' onClick={() => { setOpenSocial(!openSocial) }} />
                <BottomBarBoxTitle style={{
                    color: '#00FF85',
                }}>
                    Social
                </BottomBarBoxTitle>
                <BottomBarBoxComingSoon style={{
                    display: 'none'
                }}>
                    Coming Soon
                </BottomBarBoxComingSoon>
                {openSocial && <SocialsContainer>
                    <a href="https://www.facebook.com/profile.php?id=61552263928978&locale=cy_GB"><SocialsContainerIcon src='/images/mobile-assets/facebook.svg' /></a>
                    <a href="https://www.instagram.com/mimagames_/"><SocialsContainerIcon src='/images/mobile-assets/instagram.svg' /></a>
                </SocialsContainer>}
            </BottomBarBox>
            <BottomBarBox className='dock-technology'>
                <BottomBarBoxImage src='/images/mobile-assets/technology.svg' style={{
                    opacity: 0.7
                }} />
                <BottomBarBoxTitle style={{
                    color: '#00CFFF',
                    opacity: 0.7
                }}>
                    About
                </BottomBarBoxTitle>
                <BottomBarBoxComingSoon style={{
                    display: 'none'
                }}>
                    Coming Soon
                </BottomBarBoxComingSoon>
            </BottomBarBox>
		</MobileBottomBarContainer>
	)
}

export default MobileBottomBar;


const BottomBarBoxImage = styled.img`
    margin-bottom: 5px;
    background-color: #000000df;
    border-radius: 50%;
`

const MobileBottomBarBack = styled.div`
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: #000000cf;
    width: 100%;
    height: 75%;
`

const BottomBarBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 20%;
    max-width: 140px;
    padding: 0 0.5rem;
`


const BottomBarBoxTitle = styled.h3`
    margin: 0px;
    font-size: 8px;
    text-transform: uppercase;
    font-weight: bold;
`

const BottomBarBoxComingSoon= styled.h4`
    position: absolute;
    font-size: 6px;
    top: 15px;
`

const SocialsContainer= styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 65px;
`

const SocialsContainerIcon = styled.img`
    margin-bottom: 5px;
`

const MobileBottomBarContainer = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: ${props => props.inGame==='1' ? `0.5rem` : `0.5rem`};
    .dock-active {
        img {
            padding: 2px;
            border: 1px solid white;
            border-radius: 50%;
            opacity: 1;
        }
        h3 {
            font-size: 11px;
            font-weight: bold;
            opacity: 1;
        }
    }
    ${props => props.isInGame==='1' ? css`
        .dock-active{
            padding: 0;
        }
    ` : ``}

    ${BottomBarBoxImage}{
        width: ${props => props.isInGame==='1' ? `44px` : `48px`};
    }
`