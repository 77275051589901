import React, { lazy, Suspense, useEffect } from 'react'

import './i18n'

import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import AppProvider from './contexts/AppProvider'
import ScrollToTop from './components/Utils/ScrollToTop'
import ProtectedRoute from './components/Authentication/ProtectedRoute/ProtectedRoute'

import MainLayout from './components/Layout/MainLayout'
import ThankYou from './components/Utils/ThankYou/ThankYou'

import Footer from './components/General/Footer/Footer'
import SingleGameLivePokerFree from './pages/SingleGameLivePokerFree'

//Account Pages
import Login from './pages/Auth/Login'

//General Page Templates
import ChangePhoneNumberPage from './pages/Profile/ChangePhoneNumberPage'
import LegalPage from './pages/LegalPage'
import GuidePage from './pages/GuidePage'
import AboutPage from './pages/AboutPage'
import BlogPostPage from './pages/BlogPostPage'
import { useTranslation } from 'react-i18next'
import { useUserLang } from './contexts/LanguageSwitcherProvider'
import SplashScreen from './components/Sections/MobileAppSplashScreen/SplashScreen'
import DeleteAccountPage from './pages/DeleteAccountPage'
import MobileBottomBar from './components/Navigation/MobileBottomBar/MobileBottomBar'
import QuickRegister from './components/Authentication/QuickRegister/QuickRegister'

const Header = lazy(() => import('./components/General/Header/Header'))

const LandingPromoPage = lazy(() => import('./pages/LandingPromoPage'))

const PersonalInformationPage = lazy(() => import("./pages/Profile/PersonalInformationPage"))
const Activate = lazy(() => import('./pages/Auth/Activate' ))
const ChangePasswordPage = lazy(() => import('./pages/Auth/ChangePasswordPage' ))
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'))
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'))


function App() {

	const { i18n } = useTranslation()
	const { lang } = useUserLang()

	useEffect(() => {
		if(localStorage.getItem("lang")){
			i18n.changeLanguage(localStorage.getItem("lang"))
		}
	}, [i18n])

	return (
		<Router>
			<ScrollToTop>
				<HelmetProvider>
					<AppProvider>
						<Suspense fallback={<></>}>
							<Header />
						</Suspense>
							<MainLayout>
								<Suspense fallback={<></>}>
									<Routes>
										{ lang === 'en' && <Route path={`*`} element={<LocaleRouterWrapper />} /> }
										{ lang !== 'en' && <Route path={`/:locale/*`} element={<LocaleRouterWrapper />} /> }
									</Routes>
								</Suspense>
							</MainLayout>
						<MobileBottomBar />
						<Footer />
						<SplashScreen />
					</AppProvider>
				</HelmetProvider>
			</ScrollToTop>
		</Router>
	)
}

export default App;


const LocaleRouterWrapper = () => {

	const { locale } = useParams()
	const { setLang } = useUserLang()
	const { t } = useTranslation()

	useEffect(() => {
		if(locale){
			setLang(locale)
		}else{
			setLang('en')
		}
	}, [locale, setLang])

	return (
		<Routes>
			<Route path="*" element={<ThankYou title='404' text={t('Page not found...')} showContact={false} />} />

			<Route exact path="/login" element={<Login/>} />
			<Route exact path="/register" element={<QuickRegister/>} />

			<Route exact path="/activate/:uuid64/:token" element={<Activate/>} />
			<Route exact path="/forgot-password" element={<ForgotPassword/>} />
			<Route exact path="/password_reset/confirm/:token" element={<ResetPassword/>} />
			<Route index element={<LandingPromoPage />} />
			
			<Route element={<ProtectedRoute />}>
				<Route exact path="/game/free-poker_mima/play/free/:external_id" element={<SingleGameLivePokerFree />} />
				<Route exact path="/game/live-poker_mima/play/free/:external_id" element={<SingleGameLivePokerFree />} />
				<Route exact path="/my-account/edit-profile" element={<PersonalInformationPage/>} />
				<Route exact path="/my-account/change-password" element={<ChangePasswordPage/>} />
				<Route exact path="/my-account/change-phone-number" element={<ChangePhoneNumberPage/>} />
				<Route exact path="/my-account/delete-account" element={<DeleteAccountPage/>} />
			</Route>

			<Route exact 
				path="/register-thank-you" 
				element={<ThankYou src='/images/thankyoupositive.png' 
				title='Thank You for Registering!' 
				text='You will receive a verification email to activate your account. Please check your inbox.'
				button={{ to: '/login', label: 'Continue to Login'  }} />} />
			
			<Route exact path="/how-to-play/:slug" element={<BlogPostPage/>} />
			<Route exact path="/contact-us" element={<AboutPage slug="contact-us" />} />
			<Route exact path="/security-privacy/:slug" element={<LegalPage/>} />
			<Route exact path="/guides/:slug" element={<GuidePage/>} />

		</Routes>
	)
}