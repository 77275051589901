import React from "react";
import styled from "styled-components";

import useGetWPAxios from "../api/useGetWPAxios";
import LoadingOutput from "../components/Output/LoadingOutput/LoadingOutput";
import Spacer from "../components/Utils/Spacer/Spacer";
import { t } from "i18next";

export default function AboutPage({ slug }){

    const { data, error } = useGetWPAxios('wp/v2/pages?slug=' + slug)

    if(!data || error){
        return <LoadingOutput>{ t("Loading page...")}</LoadingOutput>
    }

    const page = data[0]

    return(
        <AboutWrap>
            <Spacer size="50" />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <h1>{ page.title.rendered }</h1>
                        <Body dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
                    </div>
                </div>
            </div>
            <Spacer size="100" />
        </AboutWrap>
    );
}

const AboutWrap = styled.section``

const Body = styled.div``