import React from "react"
import TermsAndConditions from "../components/Help/TermsAndConditions/TermsAndConditions";
import TermsSidebar from "../components/Navigation/TermsAndConditons/TermsSidebar"

const LegalPage = () => {
    return (
        <div className="legal-page">
            <div className='container'>
                <div className="row">
                    <div className="col-lg-9 order-lg-2">
                        <div className="legal-content">
                            <TermsAndConditions />
                        </div>
                    </div>
                    <div className="col-lg-3 order-lg-1">
                        <TermsSidebar />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegalPage